import { Route, Routes } from "react-router-dom";
import "./App.css";
import Register from "./screens/auth/Register";
import Login from "./screens/auth/Login";
import Home from "./screens/Home/Home";
import Profile from "./screens/Profile/Profile";
import History from "./screens/History";
import VIP from "./screens/VIP";
import AboutUs from "./screens/aboutUs";
import Promotion from "./screens/promotion";
import RuleDescription from "./screens/RuleDescription";
import Vip_details from "./screens/vip_detatils";
import Invite from "./screens/Invite";
import toast, { Toaster } from "react-hot-toast";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import AdminLogin from "./Admin/auth/AdminLogin";
import Dashboard from "./Admin/dashboard/Dashboard/Dashboard";
import UserManagement from "./Admin/dashboard/users/User";
import UserTranscation from "./Admin/dashboard/userTranscation/userTranscation";
import AllHistory from "./Admin/dashboard/History/AllHistory";
import Order_Grab_Rules from "./screens/Order_Grab_Rules";
import { fetchToken, onMessageListener } from "./firebase";
import { useEffect, useState } from "react";
import { Base_url } from "./utils/Base_url";
import axios from "axios";
import addNotification, { Notifications } from "react-push-notification";
import VerifyPassword from "./screens/auth/forgetPassword/verifyPassword";
import ChangePassword from "./screens/auth/forgetPassword/ChangePassword";
function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    fetchToken(setTokenFound);
    onMessageListener()
      .then((payload) => {
        console.log(payload);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
        setShow(true);
        console.log({
          title: payload.notification.title,
          body: payload.notification.body,
        });

        const title = payload.notification.title;
        const body = payload.notification.body;
        const message = `${title}: ${body}`;

        toast.success(message, {
          duration: 4000, // Duration in milliseconds
        });

        console.log(payload);
      })
      .catch((err) => console.log("failed: ", err));

    const onShowNotificationClicked = () => {
      setNotification({
        title: "Notification",
        body: "This is a test notification",
      });
      setShow(true);
    };

    const fcm = localStorage.getItem("fcmToken");
    const id = JSON.parse(localStorage.getItem("harbor_user_id"));
    const params = new FormData();

    params.append("fcmtoken", fcm);

    axios
      .put(`${Base_url}/apis/user/update/${id?._id}`, params)
      .then((res) => {
        console.log(res, "update token..............=================");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className=" ">
      <Notifications />
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
        
      <Routes basename="https://harborcapitalclub.com">
        <Route
          path="/:id"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />


<Route
          path="/verify_password"
          element={
            <PublicRoute>
              <VerifyPassword />
            </PublicRoute>
          }
        />


<Route
          path="/change_password"
          element={
            <PublicRoute>
              <ChangePassword />
            </PublicRoute>
          }
        />
      
        <Route element={<PrivateRoute />}>
          <Route path="/home" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/history" element={<History />} />
          <Route path="/vip" element={<VIP />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/promotion" element={<Promotion />} />
          <Route path="/rule_description" element={<RuleDescription />} />
          <Route path="/vip_details" element={<Vip_details />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/order_grab_rules/:id" element={<Order_Grab_Rules />} />
        </Route>

        <Route path="admin">
          <Route path="auth">
            <Route path="admin-login" element={<AdminLogin />} />
          </Route>

          <Route path="dashboard" element={<Dashboard />} />

          <Route path="user" element={<UserManagement />} />

          <Route path="user_transcation" element={<UserTranscation />} />
          <Route path="all_history" element={<AllHistory />} />
        </Route>
      </Routes>
    </div>

  );
}

export default App;
