import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import QRCode from "react-qr-code";
const Invite = () => {
  const user = JSON.parse(localStorage.getItem("harbor_user_id"));
  var base_url = window.location.origin;
  const qrValue = user ? `${base_url}/${user.referralCode}` : "";
  return (
    <div className="  bg-Hero  flex justify-center items-center bg-center bg-cover h-[100vh] ">
      <div>
        <h1 className="text-2xl pt-16 text-center text-white mb-8">
          Refer friends Earn bonuses
        </h1>
        <div className="bg-transparent border-2 mx-auto   flex flex-col justify-between items-end  bg-black w-72 h-72 rounded-xl border-opacity-20 backdrop-blur-md">
          <div
            className=" pt-7"
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: 150,
              width: "100%",
            }}
          >
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={qrValue}
              viewBox={`0 0 256 256`}
            />
          </div>

          <CopyToClipboard
            text={qrValue}
            onCopy={() => toast.success("Copied!")}
          >
            <button className="mb-10 mx-auto text-center w-64 py-2 rounded-full mt-5 text-white border">
              Copy Link
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default Invite;
