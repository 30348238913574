
const AdminNav = ({openSidebar}) => {
   
    return(
     <nav className="fixed left-0 sm:left-64 top-2 right-0 mx-4">
      <div className="bg-black rounded-xl border w-full flex justify-between items-center p-4">
     <div className=" flex  gap-2">
     <i className="bi bi-filter-left text-white text-2xl cursor-pointer sm:hidden block" onClick={openSidebar}></i>
      <h2 className=" text-primary text-white text-lg font-medium">HARBOR CAPITAL CLUB</h2>
     </div>
       <button className="py-2 px-4   bg-[#685442] text-white rounded-md capitalize" >logout</button>
      </div>
     </nav>
    )
}
export default AdminNav;