import React from "react";
import NftProduct from "../../components/NftProduct/NftProduct";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";
import Category from "../../components/category/Category";
import Navbar from "../../components/navbar/Navbar";
import SocialMedia from "../../components/SocialMedia";
// import Announcement from "../../components/navbar/Anouncement";
const Home = () => {
  return (
    <div>
      {/* <Announcement/> */}
      <Navbar/>
      <SocialMedia/>
      <div className="mb-10">
        <Category/>
        <NftProduct />
        <BottomNavbar />
      </div>
    </div>
  );
};

export default Home;
