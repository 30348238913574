import React from "react";
import Navbar from "../../components/navbar/Navbar";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";

const RuleDescription = () => {
  return (
    <div>
      <Navbar />
      <div className=" h-[70vh] ">
        <img
          src={require("../../assets/image/slide-image02.jpg")}
          alt=""
          className=" w-full h-full object-cover"
        />
      </div>

      <div className="  container mx-auto py-8 md:px-12 px-6 bg-transparent border rounded-md mt-8 shadow-xl border-white border-opacity-20 backdrop-blur-md mb-28">
        <div>
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-[#AF7B2B]  pl-2">
            Rule description
          </h2>

          <p className=" text-white pt-5">
            Since Harbor members come from different countries and use differnet
            currences, Harbor uses cryptocurrency transactions to simplify
            transactions, Please check Harbor USDT address carefully before
            recharging (the platform recharge address can be changed at any
            time, and users must go to platform to obtain the latest recharge
            address before recharging). If you have any questions, please
            cnatact customer service.
          </p>
        </div>

        <div className=" pt-6">
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-[#AF7B2B] pl-2">
            About order amount
          </h2>

          <p className=" text-white pt-5">
            The order amount paid by Harbor members is determined by the members
            account balance, access to orders, and markets conditions on the
            day. The maximum number of orders each account can send per day
            depends on your user level.
          </p>
        </div>

        <div className=" pt-6">
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-[#AF7B2B]  pl-2">
            About order-grab commission
          </h2>
        </div>
        <div className=" overflow-x-auto">
          <table className="table mt-5  overflow-x-auto w-full text-center border">
            <thead className=" bg-yellow-400  rounded-tl-lg rounded-tr-lg">
              <td className=" py-1 font-medium text-white  whitespace-nowrap ">
                Level
              </td>
              <td className="py-1 font-medium text-white whitespace-nowrap">
                Amount
              </td>
              <td className="py-1 font-medium text-white whitespace-nowrap">
                Income
              </td>
              <td className="py-1 font-medium text-white whitespace-nowrap">
                Number of orders{" "}
              </td>
              <td className="py-1 font-medium text-white whitespace-nowrap">
                Percentage commission per order{" "}
              </td>
              <td className="py-1 font-medium text-white whitespace-nowrap">
                Percentage comission for all 7 orders a day{" "}
              </td>
            </thead>
            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 1</td>
              <td className="py-1 font-medium">$50-$99</td>
              <td className="py-1 font-medium">$0.735-$1.47</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>

            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 2</td>
              <td className="py-1 font-medium">$100-$199</td>
              <td className="py-1 font-medium">$1.47-$2.94</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>

            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 3</td>
              <td className="py-1 font-medium">$200-$499</td>
              <td className="py-1 font-medium">$2.94-7.35</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>

            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 4</td>
              <td className="py-1 font-medium">$500-$999</td>
              <td className="py-1 font-medium">$7.35-14.7</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>

            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 5</td>
              <td className="py-1 font-medium">$1000-$1999</td>
              <td className="py-1 font-medium">$14.7-29.4</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>

            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 6</td>
              <td className="py-1 font-medium">$2000-$5999</td>
              <td className="py-1 font-medium">$29.4-73.5</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>

            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 7</td>
              <td className="py-1 font-medium">$5000-$7999</td>
              <td className="py-1 font-medium">$73.5-117.6</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>

            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 8</td>
              <td className="py-1 font-medium">$8000-$11999</td>
              <td className="py-1 font-medium">$117.6-176.7</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>

            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 9</td>
              <td className="py-1 font-medium">$12000-$14999</td>
              <td className="py-1 font-medium">$176.7-220.5</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>

            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 10</td>
              <td className="py-1 font-medium">$15000-$19999</td>
              <td className="py-1 font-medium">$220.5-294</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>

            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 11</td>
              <td className="py-1 font-medium">$20000-$24999</td>
              <td className="py-1 font-medium">$294-367.5</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>

            <tbody className=" bg-yellow-100">
              <td className="py-1 font-medium">VIP 12</td>
              <td className="py-1 font-medium">$25000+</td>
              <td className="py-1 font-medium">367.5+</td>
              <td className="py-1 font-medium">7</td>
              <td className="py-1 font-medium">0.21%</td>
              <td className="py-1 font-medium">
                7 orders multiply by 0.21% comission = 1.47% daily profit
              </td>
            </tbody>
          </table>
        </div>
        <div className=" pt-6">
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-[#AF7B2B]  pl-2">
            About withdrawal
          </h2>

          <ul className=" p-0 pt-3 ">
            <li className=" text-white">
              1.The withdrawal from of Harbor usually within 72 hours, and the
              serivce fee for each withdrawal will be charged a certain fee by
              the cryptocurrency system.
            </li>
            <li className=" text-white">
              2.In order to avoid frequest payment and cause Harbor to incur
              additional texes, the minimum withdrawal amount is 8 USDT
            </li>
          </ul>
        </div>

        <div className=" p-5  rounded-md flex flex-col bg-black mt-4 md:w-[500px] w-full">
          <span className="text-white font-medium text-md">
            At the request of relevant departments, in order to prevent members
            from being suspected of money laundering and other illegal
            activities, Harbor members need to the user level every day before
            they can withdraw.
          </span>
        </div>

        <div>
          {/* <img src={require('../../assets/image/illustration-making.png')}   width={'20px'} alt="" /> */}
        </div>

        <div className=" pt-6">
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-[#AF7B2B]  pl-2">
            About level requirements
          </h2>

          <p className=" text-white pt-5">
            You can increase your user level by completing various metrics.
            please refer to the current level progress, benefits and upgrade
            conditions for each level.
          </p>
        </div>

        <div className=" pt-6">
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-[#AF7B2B]  pl-2">
            Disclaimer
          </h2>

          <p className=" text-white pt-5">
            The Commission and reward obtained by the invited members with be
            distributed to the account of the inviter every day. invites can
            view the commissions and rewards in fund details.
          </p>
        </div>

        {/* <img src={require('../../assets/image/invite.png')} alt="" /> */}
      </div>

      <BottomNavbar />
    </div>
  );
};

export default RuleDescription;
