import React, { useState, useEffect } from "react";
import { PiCopySimpleFill } from "react-icons/pi";
import { t } from "i18next";
import Navbar from "../../components/navbar/Navbar";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";
import { FaAngleRight } from "react-icons/fa";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../components/MyModal/MyModal";
import { MdClose } from "react-icons/md";
import toast from "react-hot-toast";
const Profile = () => {
  const user = JSON.parse(localStorage.getItem("harbor_user_id"));
  const [userName, setUserName] = useState([]);
  const [referralEarning, setReferralEarnings] = useState(null);
  const [profile, setProfile] = useState({});
  const [depoist, setDepoist] = useState(0);
  useEffect(() => {
    axios
      .get(`${Base_url}/apis/user/get/${user?._id}`)
      .then((res) => {
        console.log(res);
        setProfile(res.data.data);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/apis/deposit/user/${user?._id}/null/null`)
      .then((res) => {
        console.log(res, "depoist");
        // setDepoist(res.data.data);

        const saveFilter = res.data.data.filter((i) => {
          return i.verify === true;
        });

        const save = saveFilter?.map((product) => product.amount);
        const totalDepositBonus = save?.reduce(
          (accumulator, currentValue) =>
            parseInt(accumulator) + parseInt(currentValue),
          0
        );

        setDepoist(totalDepositBonus);
        console.log(
          "=========Total Purchase Price===========",
          totalDepositBonus
        );
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/apis/referralEarning/today/${user?._id}`)
      .then((res) => {
        console.log(res.data, "today");
        setUserName(res.data);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/apis/referralEarning/getUser/${user?._id}`)
      .then((res) => {
        console.log(res.data, "referral earning....");

        setReferralEarnings(res.data.earnings);
      })
      .catch((error) => {});
  }, []);

  const navigate = useNavigate();
  const removeFun = () => {
    localStorage.removeItem("harbor_user_id");
    navigate("/");
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedImages, setSelectedImages] = useState(null);
  const [loading, setLoader] = useState(false);

  console.log(selectedImages);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedImages(file);
    
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        UploadProfile();
      };
      reader.readAsDataURL(file);
    }
  };

  console.log(selectedImages);









  const UploadProfile = () => {

    console.log(selectedImages,'sdddddddddddddddddddd')
    const params = new FormData();
    params.append("image",selectedImages);

    axios
      .put(`${Base_url}/apis/user/update/${user?._id}`, params)
      .then((res) => {
        console.log(res);
        localStorage.setItem("harbor_user_id", JSON.stringify(res?.data?.data));
      })
      .catch((error) => {});
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };



  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

 

  const [loginPassword,setLoginPassword] = useState('')
  const [loginConPassword,setLoginConPassword] = useState('')



  const ChangePassword = (e) => {
 
    console.log(e);
    e.preventDefault();

    if (!loginPassword) {
      toast.error("Must enter passwrod");
    } else if (loginPassword != loginConPassword) {
      toast.error("password and confirm password must be same");
    } else {
      setLoader(true);
      const params = new FormData();
      params.append("loginPassword",loginPassword);
      axios
        .put(`${Base_url}/apis/user/update/${user?._id}`, params)
        .then((res) => {
          console.log(res);
          localStorage.setItem(
            "harbor_user_id",
            JSON.stringify(res?.data?.data)
          );

          toast.success("Change passwrod successfully!");
          setLoader(false);
          setIsModalOpen(false)
        })
        .catch((error) => {});
    }
  };



  const [withPassword,setWithPassword] = useState('')
  const [withConPassword,setWithConPassword] = useState('')

  const ChangeWithdrawal= (e) => {
 
    console.log(e);
    e.preventDefault();

    if (!withPassword) {
      toast.error("Must enter passwrod");
    } else if (withPassword != withConPassword) {
      toast.error("password and confirm password must be same");
    } else {
      setLoader(true);
      const params = new FormData();
      params.append("withdrawPassword",withPassword);
      axios
        .put(`${Base_url}/apis/user/update/${user?._id}`, params)
        .then((res) => {
          console.log(res);
          localStorage.setItem(
            "harbor_user_id",
            JSON.stringify(res?.data?.data)
          );

          toast.success("Change passwrod successfully!");
          setLoader(false);
          setIsModalOpen2(false)
        })
        .catch((error) => {});
    }
  };

  return (
    <>
      <Navbar />
      <div className=" w-full    mb-36  grid  grid-cols-1  md:grid-cols-1 gap-12">
        <div className="   rounded-b-3xl">
          <div className="mx-auto text-center">
            <div className=" bg-BG py-9 rounded-bl-3xl  rounded-br-3xl">
              <div className="w-28  h-28 text-center  mx-auto  border-2 border-yellow-500  mb-3 rounded-full  overflow-hidden">
                {user?.image ? (
                  <img
                    src={
                      selectedImage
                        ? selectedImage
                        : `${Base_url}/${user?.image}`
                    }
                    className="w-full h-full"
                  />
                ) : (
                  <img
                    src={
                      selectedImage
                        ? selectedImage
                        : require("../../assets/image/profile.jpg")
                    }
                    className="w-full h-full"
                  />
                )}
              </div>

              <div className="mx-auto">
                <div className="  my-5">
                  <label
                    htmlFor="fileInput"
                    className="px-12 py-2  text-white  font-semibold text-primary border   border-gray-200 rounded-lg cursor-pointer"
                  >
                    Upload File
                  </label>
                  <input
                    accept="image/*"
                    onChange={handleFileChange}
                    name="profileImage"
                    type="file"
                    id="fileInput"
                    className="hidden"
                  />
                </div>
              </div>

              <div>
                <span className=" text-white font-semibold text-xl">
                  {user?.userName}
                </span>
                <div>
                  <span className=" text-white font-semibold ">
                    Total Earnings
                    {parseFloat(
                      profile?.wallet === undefined || profile?.wallet === null
                        ? 0
                        : parseFloat(profile?.wallet) + depoist
                    ).toFixed(1)}
                  </span>
                </div>

                <div className=" md:flex  block   justify-center py-6">
                  {/* <div className="">
              <span className="  font-semibold text-white">Total Balance</span>
              <div>
                <span className=" text-white font-semibold text-xl pt-4">
                  $
                  {parseFloat(
                    profile?.wallet === undefined || profile?.wallet === null
                      ? 0
                      : parseFloat(profile?.wallet) + depoist
                  ).toFixed(1)}
                </span>
              </div>
            </div> */}
                  {/* <hr className=" my-2" /> */}
                  <div className=" md:flex block  gap-8">
                    <div className="pt-4">
                      <span className=" text-white font-semibold text-xl pt-4">
                        {/* ${userName?.earnings === undefined || null ? 0 : userName?.earnings} */}
                        $
                        {parseFloat(
                          userName?.earnings === undefined || null
                            ? 0
                            : userName?.earnings
                        ).toFixed(1)}
                        {/* ${users?.wallet === undefined || null ? 0 : users?.wallet} */}
                      </span>
                      <p className="  font-semibold text-white">
                        Daily Earnings
                      </p>
                    </div>
                    <div className="pt-4">
                      <span className=" text-white font-semibold text-xl pt-4">
                        {/* $
                  {users?.depositBonus === undefined || null
                    ? 0
                    : users?.depositBonus} */}
                        $
                        {parseFloat(
                          profile?.depositBonus === undefined || null
                            ? 0
                            : profile?.depositBonus
                        ).toFixed(1)}
                      </span>
                      <p className="  font-semibold text-white">
                        Deposit bonus
                      </p>
                    </div>
                    <div className="pt-4">
                      <span className=" text-white font-semibold text-xl pt-4">
                        {/* $ */}
                        {/* {users?.wallet === undefined || null
                    ? 0
                    : users?.wallet - users?.depositBonus} */}
                        $
                        {referralEarning == null || undefined
                          ? 0
                          : referralEarning}
                      </span>
                      <p className="  font-semibold text-white">
                        referral bonus
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container md:w-[80%] px-6 w-[100%] mx-auto">
          <div className="">
            <h2 className=" text-white text-xl pb-3">Personal Info</h2>
            <div className=" flex flex-col gap-6">
              <div className="  bg-black  p-3 border  rounded-md">
                <div className=" flex justify-between  p-3 pb-4 items-center">
                  <sapn className=" text-white">Real name</sapn>
                  <sapn className=" text-white flex items-center gap-3">
                    <span className=" text-white font-medium">
                      {" "}
                      {profile?.realName}
                    </span>
                    <FaAngleRight color="white" />
                  </sapn>
                </div>
                <hr className="" />
                <div className=" p-3 flex justify-between pb-4 items-center">
                  <sapn className=" text-white">Gender</sapn>
                  <sapn className=" text-white flex items-center gap-3">
                    <span className=" text-gray-500 font-medium">Not set</span>
                    <FaAngleRight color="white" />
                  </sapn>
                </div>
                <hr className="" />
                <div className=" flex p-3 justify-between pb-4 items-center">
                  <sapn className=" text-white">Date of birth</sapn>
                  <sapn className=" text-white flex items-center gap-3">
                    <span className=" text-gray-500 font-medium">Not set</span>
                    <FaAngleRight color="white" />
                  </sapn>
                </div>
                <hr className="" />
                <div className=" flex p-3 justify-between pb-4 items-center">
                  <sapn className=" text-white">Phone number</sapn>
                  <sapn className=" text-white flex items-center gap-3">
                    <span className=" text-white font-medium">
                      {profile?.phone}
                    </span>
                    <FaAngleRight color="white" />
                  </sapn>
                </div>
                <hr className="" />
                <div className=" flex p-3 justify-between pb-4 items-center">
                  <sapn className=" text-white">Email</sapn>
                  <sapn className=" text-white flex items-center gap-3">
                    <span className=" text-gray-500 font-medium">Not set</span>
                    <FaAngleRight color="white" />
                  </sapn>
                </div>
              </div>

              <div className="  bg-black  p-3 border  rounded-md">
                <div className=" flex justify-between  p-3 pb-4 items-center">
                  <sapn className=" text-white">Set password</sapn>
                </div>
                <hr className="" />
                <div
                  onClick={openModal}
                  className=" cursor-pointer p-3 flex justify-between pb-4 items-center"
                >
                  <sapn className=" text-white">Change login password</sapn>
                  <sapn className=" text-white flex items-center gap-3">
                    <FaAngleRight color="white" />
                  </sapn>
                </div>
                <hr className="" />
                <div onClick={openModal2} className=" cursor-pointer  flex p-3 justify-between pb-4 items-center">
                  <sapn className="  text-white">
                    Change withdrawal password
                  </sapn>
                  <sapn className=" text-white flex items-center gap-3">
                    <FaAngleRight color="white" />
                  </sapn>
                </div>
                <hr className="" />
                <div
                  onClick={removeFun}
                  className=" cursor-pointer flex p-3 justify-between  items-center"
                >
                  <sapn className="  text-red-700">Sign out</sapn>
                  <sapn className=" text-white flex items-center gap-3">
                    <FaAngleRight color="white" />
                  </sapn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomNavbar />

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        className={" md:w-[60%] w-[100%]"}
      >
        {/* Modal Content */}
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize  text-white h4">Change Login password</h1>
            <MdClose
              onClick={() => setIsModalOpen(false)}
              size={25}
              color="white"
            />
          </div>
          <hr />
          <form onSubmit={ChangePassword} className=" p-5">
            <div className=" flex flex-col gap-5">
              <div>
                <div className=" mt-3">
                  <label className=" pb-2 text-white font-medium">
                    Enter Password
                  </label>

                  <input
                    name="loginPasswrod"
                    onChange={(e)=>setLoginPassword(e.target.value)}
                    type="text"
                    placeholder="Please enter password"
                    className="w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                  />
                </div>
              </div>

              <div>
                <div className="">
                  <label className=" pb-2 text-white font-medium">
                    Enter Confirm Password
                  </label>

                  <input
                   name="confirmPasswrod"
                   onChange={(e)=>setLoginConPassword(e.target.value)}
                   
                    type="text"
                    placeholder="Please enter password"
                    className="w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                  />
                </div>
              </div>

              <div>
                {loading === true ? (
                  <button
                    disabled
                    type="button"
                    class="bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className=" bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal>



      <Modal
        isOpen={isModalOpen2}
        onClose={closeModal2}
        className={" md:w-[60%] w-[100%]"}
      >
        {/* Modal Content */}
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize  text-white h4">Change Withdrawal Password</h1>
            <MdClose
              onClick={() => setIsModalOpen2(false)}
              size={25}
              color="white"
            />
          </div>
          <hr />
          <form onSubmit={ChangeWithdrawal} className=" p-5">
            <div className=" flex flex-col gap-5">
              <div>
                <div className=" mt-3">
                  <label className=" pb-2 text-white font-medium">
                    Enter Withdrawal Password
                  </label>

                  <input
                    name="loginPasswrod"
                    onChange={(e)=>setWithPassword(e.target.value)}
                    type="text"
                    placeholder="Please enter password"
                    className="w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                  />
                </div>
              </div>

              <div>
                <div className="">
                  <label className=" pb-2 text-white font-medium">
                    Enter Confirm Password
                  </label>

                  <input
                   name="confirmPasswrod"
                   onChange={(e)=>setWithConPassword(e.target.value)}
                   
                    type="text"
                    placeholder="Please enter password"
                    className="w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                  />
                </div>
              </div>

              <div>
                {loading === true ? (
                  <button
                    disabled
                    type="button"
                    class="bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className=" bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Profile;
