import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import BottomNavbar from '../../components/bottomNavbar/BottomNavbar'

const Vip_details = () => {
  return (
    <div>
        <Navbar/>
       <div className=" bg-black h-20 flex justify-between items-center">
            <div className=" pl-5">
              <div>
                <div className=" "></div>
                <div>
                    <img src={require('../../assets/image/users.png')} className=' rounded-full' alt='' />
                  <p className=" text-white">alam006</p>
                </div>
              </div>
            </div>
            <div>
              <button className=" bg-green-600 text-white text-sm px-4 py-1">
                Completed
              </button>
            </div>
          </div> 
          <div className=" p-5">
            <div className=" flex flex-col">
              <sapn className=" text-white">My total assets</sapn>
              <sapn className=" text-white text-xl">163.72</sapn>
            </div>


            <div className=' flex justify-between items-center'>
            <div className=" flex flex-col">
              <sapn className=" text-white">Grabbed/ Total</sapn>
              <sapn className=" text-white text-xl">0/7</sapn>
            </div>
            <div className=" flex flex-col">
              <sapn className=" text-white">Promotion bonus</sapn>
              <sapn className=" text-white text-xl">115.14</sapn>
            </div>
            <div className=" flex flex-col">
              <sapn className=" text-white">Today's profile</sapn>
              <sapn className=" text-white text-xl">0</sapn>
            </div>
            </div>
           
          </div>
          <BottomNavbar/>
    </div>
  )
}

export default Vip_details