import { Link } from "react-router-dom";
const Sidebar = ({ side, closeSidebar }) => {
  return (
    <div
      className={`fixed top-0 m-0 md:m-2 ${side} md:left-0 w-64  md:h-[97vh] h-screen  rounded-lg border  dark:bg-dark dark:border-[#1f1f1f]    bg-black   z-40 transition-all`}
    >
      <i
        className="bi bi-x-lg absolute top-4 right-4 sm:hidden block cursor-pointer text-lg"
        onClick={closeSidebar}
      ></i>
      <div className=" p-4">
        <img src={require('../assets/image/logo.png')} className=" w-36 mx-auto" alt="logo" />
      </div>
      <ul className="mt-4 flex flex-col">
        <li className="px-4     cursor-pointer transition-all py-3 text-white flex items-center hover:bg-[#685442]">
          <i className="bi bi-card-list mr-2 inline-block text-lg"></i>{" "}
          <Link to="/admin/dashboard" className="text-base  font-semibold capitalize">
            Dashboard
          </Link>
        </li>
        <li className="px-4 bg-primary cursor-pointer transition-all py-3 text-white flex items-center hover:bg-[#685442]">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <Link to="/admin/user" className="text-base capitalize">
            User managment
          </Link>
        </li>
        <li className="px-4 bg-primary cursor-pointer transition-all py-3 text-white flex items-center hover:bg-[#685442]">
          <i className="bi bi-people-fill mr-2 inline-block text-lg"></i>{" "}
          <Link to="/admin/user_transcation" className="text-base capitalize">
            User Transaction
          </Link>
        </li>
        <li className="px-4 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-[#685442]">
          <i className="bi bi-bar-chart mr-2 inline-block text-lg"></i>{" "}
          <Link to="/admin/all_history" className="text-base capitalize">
            History
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default Sidebar;
