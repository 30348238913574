import React from 'react'
import Wrapper from '../Wrapper'
import { VscGraph } from "react-icons/vsc";
import { FaUserFriends } from "react-icons/fa";
const Dashboard = () => {
  return (
    <Wrapper>
        <h4 className='  text-white text-2xl'>Dashboard</h4>

        <div className=' grid  grid-cols-3 pt-7 gap-3'>

            <div className=' border px-4 py-6 flex justify-between items-center rounded-lg '>

                <div>
                <VscGraph color='#685442' size={65} />
                </div>
                <div className=' flex flex-col'>
                    <span className=' text-gray-500'>Today Earnings</span>
                    <span className=' text-white text-xl'>1230</span>
                </div>

            </div>
            <div className=' border px-4 py-6 flex justify-between items-center rounded-lg '>

                <div>
                <FaUserFriends color='#685442' size={65} />
                </div>
                <div className=' flex flex-col'>
                    <span className=' text-gray-500'>Today Users</span>
                    <span className=' text-white text-xl'>1230</span>
                </div>

            </div>
        </div>
    </Wrapper>
  )
}

export default Dashboard