import React, { useEffect, useState } from "react";
import Tabs from "../../components/Tabs";
import Navbar from "../../components/navbar/Navbar";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import moment from "moment";
import { FaAngleDown } from "react-icons/fa";
import SocialMedia from "../../components/SocialMedia";

const History = () => {
  const user = JSON.parse(localStorage.getItem("harbor_user_id"));


  console.log(user);

  const [dateFilter, setDateFilter] = useState('all');

  const [dateWithdrawal, setDateWithdraw] = useState('all');
  const [dateDepoist, setDateDepoist] = useState('all');

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [orderGrab, setOrderGrab] = useState([]);
  const [depoist, setDepoist] = useState([]);
  const [withdrawal, setWithdrawal] = useState([]);
  const [team, setTeam] = useState({});
  const [users, setUsers] = useState({});
  const [totalDeposit, setTotalDeposit] = useState(0)


  const [mydownline, setMyDownLine] = useState([]);


  const [searchTerm, setSearchTerm] = useState('');
  const [filteredWithdrawals, setFilteredWithdrawals] = useState([]);


  // const save = depoist?.map((product) => product.amount);
  // const totalDepositBonus = save?.reduce(
  //   (accumulator, currentValue) =>
  //     parseInt(accumulator) + parseInt(currentValue),
  //   0
  // );

  // console.log("=========Total Purchase Price===========", totalDepositBonus);



  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };



  useEffect(() => {
    axios
      .get(`${Base_url}/apis/grab/getUser/${user?._id}`)
      .then((res) => {
        console.log(res.data);
        setOrderGrab(res.data.data);
      })
      .catch((error) => { });

    axios
      .get(`${Base_url}/apis/referralEarning/team/${user?.referralCode}`)
      .then((res) => {
        console.log(res.data);
        setTeam(res.data);
      })
      .catch((error) => { });

    // axios
    //   .get(`${Base_url}/apis/deposit/user/${user?._id}`)
    //   .then((res) => {
    //     console.log(res.data);
    //     setDepoist(res.data.data);
    //   })
    //   .catch((error) => {});

    // axios

    //   .get(`${Base_url}/apis/withdraw/user/${user?._id}/null/null`)
    //   .then((res) => {
    //     console.log(res.data);
    //     setWithdrawal(res.data.data);
    //   })
    //   .catch((error) => { });



    // axios
    // .get(`${Base_url}/apis/user/mydownline/1712709006037/2024-04-20/2024-04-26`)
    // .then((res) => {
    //   console.log(res.data,'mydownline');
    //   setMyDownLine(res.data.data);
    // })
    // .catch((error) => {});




  }, []);

  useEffect(() => {
    axios
      .get(`${Base_url}/apis/user/get/${user?._id}`)
      .then((res) => {

        console.log(res.data);
        setUsers(res.data.data);
      })
      .catch((error) => { });

    // axios
    //   .get(`${Base_url}/apis/deposit/user/${user?._id}/null/null`)
    //   .then((res) => {


    //     console.log(res);

    //     const saveFilter = res.data.data.filter((i) => {
    //       return i.verify === true;
    //     });

    //     const save = saveFilter?.map((product) => product.amount);
    //     const totalDepositBonus = save?.reduce(
    //       (accumulator, currentValue) =>
    //         parseInt(accumulator) + parseInt(currentValue),
    //       0
    //     );

    //     setTotalDeposit(totalDepositBonus);
    //     console.log(
    //       "=========Total Purchase Price===========",
    //       totalDepositBonus
    //     );



    //     console.log(res, "depoist");
    //     setDepoist(res.data.data);
    //   })
    //   .catch((error) => { });
  }, []);

  const grabTotal = orderGrab?.map((product) => product.amount);
  const totalGrabOrder = grabTotal?.reduce(
    (accumulator, currentValue) =>
      parseFloat(accumulator) + parseFloat(currentValue),
    0
  );

  console.log("=========Total Purchase Price===========", totalGrabOrder);

  const withdrawTotal = withdrawal?.map((product) => product.amount);
  const totalwithdrawOrder = withdrawTotal?.reduce(
    (accumulator, currentValue) =>
      parseFloat(accumulator) + parseFloat(currentValue),
    0
  );

  console.log("=========Total Purchase Price===========", totalwithdrawOrder);



  const DepoistTotal = mydownline?.map((product) => product.totalDeposit);
  const totalDepositData = DepoistTotal?.reduce(
    (accumulator, currentValue) =>
      parseFloat(accumulator) + parseFloat(currentValue),
    0
  );

  console.log("=========Total Purchase Price===========", totalDepositData);



  const withdrawtotal = mydownline?.map((product) => product.totalWithdraw);
  const withdrawTotalData = withdrawtotal?.reduce(
    (accumulator, currentValue) =>
      parseFloat(accumulator) + parseFloat(currentValue),
    0
  );

  console.log("=========Total Purchase Price===========", withdrawTotalData);





  const profittotal = mydownline?.map((product) => product.wallet);
  const profittotalData = profittotal?.reduce(
    (accumulator, currentValue) =>
      parseFloat(accumulator) + parseFloat(currentValue),
    0
  );

  console.log("=========Total Purchase Price===========", profittotalData);


  const depositBonustotal = mydownline?.map((product) => product.wallet);
  const depositBonustotalData = depositBonustotal?.reduce(
    (accumulator, currentValue) =>
      parseFloat(accumulator) + parseFloat(currentValue),
    0
  );

  console.log("=========Total Purchase Price===========", depositBonustotalData);





  useEffect(() => {
    fetchData(dateFilter);
    withdrawalData(dateWithdrawal);
    depoistData(dateDepoist);
  }, [dateFilter, dateWithdrawal, dateDepoist]);


  const fetchData = (filter='all') => {


    const today = new Date();
    let startDate, endDate;
    let url;

    
    switch (filter) {
      case 'today':
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()-1);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()+1);
        break;
      case 'yesterday':
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()-3);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()+1);
        break;
      case 'lastWeek':
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 8);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()+1);
        break;
      default:
        // If 'all' or any other unrecognized filter, do not limit by date
        startDate = null;
        endDate = null;
        break;
    }


    startDate = startDate ? startDate.toISOString().split('T')[0] : 'null';
    endDate = endDate ? endDate.toISOString().split('T')[0] : 'null';

    if (startDate === 'null' || endDate === 'null') {
      url = `${Base_url}/apis/user/mydownline/${user?.referralCode}/null/null`;
    } else {
      url = `${Base_url}/apis/user/mydownline/${user?.referralCode}/${startDate}/${endDate}`;
    }

    axios.get(url)
    .then((res) => {
      console.log(res.data, 'mydownline');
      setMyDownLine(res.data.data);
    })
    .catch((error) => {
      console.error('Error fetching data: ', error);
    });
  
  };


 


  const withdrawalData = (filter = 'all') => {
    const today = new Date();
    let startDate, endDate;
    let url;

    // Determine the dates based on the filter
    switch (filter) {
      case 'today':
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()-1);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        break;
      case 'yesterday':
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() -3);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()+1);
        break;
      case 'lastWeek':
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 8);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        break;
      default:
        // If 'all' or any other unrecognized filter, do not limit by date
        startDate = null;
        endDate = null;
        break;
    }


    startDate = startDate ? startDate.toISOString().split('T')[0] : 'null';
    endDate = endDate ? endDate.toISOString().split('T')[0] : 'null';

    if (startDate === 'null' || endDate === 'null') {
      url = `${Base_url}/apis/withdraw/user/${user?._id}/null/null`;
    } else {
      url = `${Base_url}/apis/withdraw/user/${user?._id}/${startDate}/${endDate}`;
    }

    axios
      .get(url)
      .then((res) => {
        console.log(res.data);
        setWithdrawal(res.data.data);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  };






  const depoistData = (filter = 'all') => {
    const today = new Date();
    let startDate, endDate;
    let url;

    // Determine the dates based on the filter
    switch (filter) {
      case 'today':
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()-1);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        break;
      case 'yesterday':
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 3);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()+1);
        break;
      case 'lastWeek':
        startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 8);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        break;
      default:
        // If 'all' or any other unrecognized filter, do not limit by date
        startDate = null;
        endDate = null;
        break;
    }


    startDate = startDate ? startDate.toISOString().split('T')[0] : 'null';
    endDate = endDate ? endDate.toISOString().split('T')[0] : 'null';

    if (startDate === 'null' || endDate === 'null') {
      url = `${Base_url}/apis/deposit/user/${user?._id}/null/null`;
    } else {
      url = `${Base_url}/apis/deposit/user/${user?._id}/${startDate}/${endDate}`;
    }

    axios
      .get(url)
      .then((res) => {

        console.log(res);

        const saveFilter = res.data.data.filter((i) => {
          return i.verify === true;
        });

        const save = saveFilter?.map((product) => product.amount);
        const totalDepositBonus = save?.reduce(
          (accumulator, currentValue) =>
            parseInt(accumulator) + parseInt(currentValue),
          0
        );

        setTotalDeposit(totalDepositBonus);
        console.log(
          "=========Total Purchase Price===========",
          totalDepositBonus
        );



        console.log(res, "depoist");
        setDepoist(res.data.data);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  };



  useEffect(() => {
    const results = withdrawal.filter(w =>
      w.walletAddress.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredWithdrawals(results);

  }, [searchTerm, withdrawal]);


  const tabData = [
    {
      title: "Order Grab history",
      content: (
        <>
          {orderGrab?.map((item, index) => {
            return (
              <div className="     mb-4  mx-auto border rounded-md">
                <div className=" bg-black h-20 flex justify-between items-center">
                  <div className=" pl-5">
                    <div>
                      <div className=" "></div>
                      <div>
                        <p className=" text-white">Order Grab History</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <button className=" bg-green-600 text-white text-sm px-4 py-1">
                  Completed
                </button> */}
                  </div>
                </div>
                <div className=" p-5">
                  <div className=" flex justify-between pb-4 items-center">
                    <sapn className=" text-white">Order number</sapn>
                    <sapn className=" text-white">{item?._id}</sapn>
                  </div>
                  <div className=" flex justify-between pb-4 items-center">
                    <sapn className=" text-white">Grab time</sapn>
                    <sapn className=" text-white">
                      {moment(item?.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </sapn>
                  </div>
                  <div className=" flex justify-between pb-4 items-center">
                    <sapn className=" text-white">Order total</sapn>
                    <sapn className=" text-white">$138.85</sapn>
                  </div>
                  <div className=" flex justify-between pb-4 items-center">
                    <sapn className=" text-white">Commission free</sapn>
                    <sapn className=" text-white">$0.35</sapn>
                  </div>
                  <div className=" flex justify-between pb-4 items-center">
                    <sapn className=" text-white">Expected refund</sapn>
                    <sapn className=" text-white text-lg">$139.21</sapn>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ),
    },

    {
      title: "Agent report",
      content: (
        <>

          <div className=" mx-auto  justify-center flex mb-4">

            <div>
              <select value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)} className=" text-sm  w-48  py-3 rounded-md bg-black border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md">
                <option value="all">Filter : All</option>
                <option value="today">Filter : Today</option>
                <option value="yesterday">Filter :  Yesterday</option>
                <option value="lastWeek">Filter :  Last Week</option>
              </select>
            </div>

          </div>


          {/* <p  className=" text-center py-4 text-white flex justify-center gap-1">Today <FaAngleDown /> </p> */}

          {/* {dropdownVisible && (
            <div className="absolute z-10 p-3 mt-2 w-96 rounded-md shadow-lg bg-white left-[30%] ring-black ring-opacity-5">
              <div className="py-1 gap-4 flex">
                <div className=" py-2">
                  <label
                    htmlFor="minValue"
                    className="block text-sm font-medium text-gray-700"
                  >
                    To date
                  </label>
                  <input
                    type="date"
                    id="minValue"
                   
                    className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className=" py-2">
                  <label
                    htmlFor="maxValue"
                    className="block text-sm font-medium text-gray-700"
                  >
                    From date
                  </label>
                  <input
                    type="date"
                    id="maxValue"
                    
                    className="mt-1 p-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <button
                className={
                  "   bg-gray-600 py-2 rounded-full w-full justify-center text-white"
                }
              >Search</button>
            </div>
          )} */}
          <div className="      mx-auto  rounded-md">
            <div className=" rounded-md border bg-black h-12 flex justify-between items-center">
              <div className=" pl-5">
                <div>
                  <div className=" "></div>
                  <div>
                    <p className=" text-white">Finanical info</p>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className=" rounded-md p-5 border-l  border-r  border-b">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Balance</sapn>
                <sapn className=" text-white">
                  {" "}
                  $
                  {parseFloat(profittotalData + depositBonustotalData
                  ).toFixed(1)}
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Depoist amount</sapn>
                <sapn className=" text-white">
                  ${parseFloat(totalDepositData).toFixed(1)}
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Withdrawal amount</sapn>
                <sapn className=" text-white">${parseFloat(withdrawTotalData).toFixed(1)}</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Profit</sapn>
                <sapn className=" text-white">
                  {parseFloat(profittotalData).toFixed(1)}
                </sapn>
              </div>
              {/* <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Grab amount</sapn>
                <sapn className=" text-white">{totalGrabOrder.toFixed(2)}</sapn>
              </div> */}
            </div>

            <div className=" rounded-md mt-3 border bg-black h-12 flex justify-between items-center">
              <div className=" pl-5">
                <div>
                  <div className=" "></div>
                  <div>
                    <p className=" text-white">Team members details:</p>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className=" rounded-md p-5 border-l  border-r  border-b">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Active people</sapn>
                <sapn className=" text-white">
                  {mydownline?.length}
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Number of team members</sapn>
                <sapn className=" text-white">
                  {team?.numberOfTeamMember ? team?.numberOfTeamMember : 0}
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Number of new members</sapn>
                <sapn className=" text-white">
                  {team?.numberNewTeam ? team?.numberNewTeam : 0}
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">First depoist members</sapn>
                <sapn className=" text-white">
                  {team?.firstDepost ? team.firstDepost : 0}
                </sapn>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Deposit History",
      content: (
        <>

          <div className=" flex md:flex-row flex-col gap-2 justify-between items-center">
            <div>
              <select value={dateDepoist}
                onChange={(e) => setDateDepoist(e.target.value)} className=" text-sm  w-48  py-3 rounded-md bg-black border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md">
                <option value="all">Filter : All</option>
                <option value="today">Filter : Today</option>
                <option value="yesterday">Filter :  Yesterday</option>
                <option value="lastWeek">Filter :  Last Week</option>
              </select>
            </div>
            <div className="  mb-2 bg-black p-1 rounded-md w-80 justify-center flex">
              <input onChange={(e) => setSearchTerm(e.target.value)} placeholder="Plase enter your account" className=" text-white p-3 w-full bg-black rounded-md" />
              <button className=" bg-[#685442] px-4 py-3 rounded-md text-white">Search</button>
            </div>
          </div>
          <section className="mb-20 text-gray-800">
            <div className="block rounded-lg shadow-lg">
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full mb-0">
                        <thead className=" bg-[#685442]">
                          <tr className=" rounded-lg whitespace-nowrap ">
                            <th
                              scope="col"
                              className=" text-sm text-white  font-bold px-6 py-4"
                            >
                              No
                            </th>
                            {/* <th
                              scope="col"
                              className=" text-sm text-white  font-bold px-6 py-4"
                            >
                              Account
                            </th> */}
                            <th
                              scope="col"
                              className=" text-sm text-white  font-bold px-6 py-4"
                            >
                              Time
                            </th>

                            <th
                              scope="col"
                              className="text-sm  text-white   font-bold px-6 py-4"
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              className="text-sm  text-white   font-bold px-6 py-4"
                            >
                              Reason
                            </th>

                            <th
                              scope="col"
                              className="text-sm  text-white   font-bold px-6 py-4"
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {depoist?.map((item, index) => {
                            return (
                              <tr className="bg-black border-t   rounded-md ">
                                <th
                                  scope="row"
                                  className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                                >
                                  <p className="mb-0.5 font-medium text-white">
                                    #1
                                  </p>
                                </th>
                                {/* <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                  <span className=" text-base text-white  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline    rounded-full">
                                    {item?.walletAddress}
                                  </span>
                                </td> */}
                                <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                  <span className=" text-base    text-gray-500  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline    rounded-full">
                                    {item?.updatedAt}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base  text-red-500 font-medium py-1 px-2.5 leading-none  whitespace-nowrap     rounded-full">
                                    {item?.amount}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base  text-white font-medium py-1 px-2.5 leading-none  whitespace-nowrap     rounded-full">
                                    {item?.reason ? item?.reason : '-'}
                                  </span>
                                </td>

                                <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                  <div className=" flex text-white justify-center gap-2">
                                    {/* {item?.verify === true
                                      ? "  Deposit successful"
                                      : "  Deposit pending"} */}
                                    {item?.status}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ),
    },
    {
      title: "Withdrawal History",
      content: (
        <>


          <div className="  gap-2 flex md:flex-row flex-col justify-between  items-center">
            <div className="">
              <select value={dateWithdrawal}
                onChange={(e) => setDateWithdraw(e.target.value)} className=" text-sm  w-48  py-3 rounded-md bg-black border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md">
                <option value="all">Filter : All</option>
                <option value="today">Filter : Today</option>
                <option value="yesterday">Filter :  Yesterday</option>
                <option value="lastWeek">Filter :  Last Week</option>
              </select>
            </div>


            <div className="  mb-2 bg-black p-1 rounded-md w-80 justify-center  flex">
              <input onChange={(e) => setSearchTerm(e.target.value)} placeholder="Plase enter your account" className=" text-white p-3 w-full bg-black rounded-md" />
              <button className=" bg-[#685442] px-4 py-3 rounded-md text-white">Search</button>
            </div>
          </div>
          <section className="mb-20  text-gray-800">
            <div className="block rounded-lg shadow-lg">
              <div className="flex overflow-hidden flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="">
                      <table className="min-w-full mb-0">
                        <thead className=" bg-[#685442]">
                          <tr className=" rounded-lg whitespace-nowrap ">
                            <th
                              scope="col"
                              className=" text-sm text-white  font-bold px-6 py-4"
                            >
                              No
                            </th>
                            <th
                              scope="col"
                              className=" text-sm text-white  font-bold px-6 py-4"
                            >
                              Account
                            </th>
                            <th
                              scope="col"
                              className=" text-sm text-white  font-bold px-6 py-4"
                            >
                              Time
                            </th>

                            <th
                              scope="col"
                              className="text-sm  text-white   font-bold px-6 py-4"
                            >
                              Amount
                            </th>

                            <th
                              scope="col"
                              className="text-sm  text-white   font-bold px-6 py-4"
                            >

                              Reason
                            </th>

                            <th
                              scope="col"
                              className="text-sm  text-white   font-bold px-6 py-4"
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {filteredWithdrawals?.map((item, index) => {
                            return (
                              <tr className="bg-black border-t   rounded-md ">
                                <th
                                  scope="row"
                                  className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                                >
                                  <p className="mb-0.5 font-medium text-white">
                                    #1
                                  </p>
                                </th>
                                <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                                  <span className=" text-base text-white  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline    rounded-full">
                                    {item?.walletAddress}
                                  </span>
                                </td>
                                <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                                  <span className=" text-base    text-gray-500  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline    rounded-full">
                                    {item?.updatedAt}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base  text-red-500 font-medium py-1 px-2.5 leading-none  whitespace-nowrap     rounded-full">
                                    {item?.amount}
                                  </span>
                                </td>
                                <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                                  <span className=" text-base  text-white  font-medium py-1 px-2.5 leading-none  whitespace-nowrap     rounded-full">
                                    {item?.reason ? item?.reason : '-'}
                                  </span>
                                </td>

                                <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                                  <div className=" flex text-white justify-center gap-2">
                                    {/* {item?.verify === true
                                      ? "  Withdrawal successful"
                                      : "  Withdrawal pending"} */}
                                    {item?.status}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ),
    },
  ];
  const defaultTab = "Order Grab history";

  return (
    <div>
      <Navbar />
      <SocialMedia/>
      <div className="container  mx-auto mt-8 md:w-[75%] w-[95%] mb-24">
        <Tabs tabs={tabData} defaultTab={defaultTab} />
      </div>

      <BottomNavbar />
    </div>
  );
};

export default History;
