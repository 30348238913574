import React from "react";
import Tabs from "../../components/Tabs";
import Navbar from "../../components/navbar/Navbar";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";
import { FaAngleRight } from "react-icons/fa";
const VIP = () => {
  const tabData = [
    {
      title: "Current level",
      content: (
        <>
          <div className="mx-auto">
            <div className="">
              <p className=" text-white">Current level(VIP2)</p>
            </div>
            <div className=" p-5 mt-3 rounded-md bg-black border border-gray-100">
              <div className=" flex justify-between pb-4 items-center">
                <img
                  src={require("../../assets/image/gold.png")}
                  className=" w-24"
                  alt=""
                />
                <sapn className=" text-white flex gap-4">
                  {" "}
                  <span>Minimum balance: </span>{" "}
                  <p className=" font-semibold text-yellow-800">150</p>{" "}
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>

            <div className=" mt-4">
              <p className=" text-white">To reach next VIP level</p>
            </div>
            <div className=" p-5 mt-3 rounded-md bg-black border  border-gray-100">
              <div className=" flex justify-between  items-center">
                <sapn className=" text-white">Balance to upgrade</sapn>
                <sapn className=" text-white text-lg">162.54/300</sapn>
              </div>
            </div>

            <div className=" mt-3 flex justify-between items-center">
              <button className="  bg-orange-500 text-white py-3  w-[48%] rounded-md">
                Invite friends
              </button>
              <button className="   bg-purple-600 text-white py-3  w-[48%] rounded-md">
                Depoist
              </button>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Summary",
      content: (
        <>
          <div className="   grid grid-cols-2 gap-3">
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP1</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP2</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP3</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP4</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP5</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP6</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP7</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP8</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP9</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP10</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP11</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
            <div className=" bg-black border p-3  rounded-md">
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">VIP12</sapn>
                <sapn className=" text-white">
                  <FaAngleRight color="white" />
                </sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Commission rate</sapn>
                <sapn className=" text-white">0.26%</sapn>
              </div>
              <div className=" flex justify-between pb-4 items-center">
                <sapn className=" text-white">Open markets</sapn>
                <sapn className=" text-white text-lg">
                  Account amount: 150~300
                </sapn>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  const defaultTab = "Current level";

  return (
    <div>
      <Navbar />
      <div className="container mx-auto mt-8 md:w-[75%] w-[95%] mb-24">
        <Tabs tabs={tabData} defaultTab={defaultTab} />
      </div>

      <BottomNavbar />
    </div>
  );
};

export default VIP;
