import React, { useState } from "react";
import { HiMiniHome } from "react-icons/hi";
import { BsFillGridFill, BsHouseDoorFill,BsClipboardCheckFill } from "react-icons/bs";
import { IoHome } from "react-icons/io5";
import { AiFillShop } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";

import { t } from "i18next";
import { MdOutlineSupportAgent } from "react-icons/md";


const BottomNavbar = () => {
  const [navbarNav, setNavbar] = useState("/");
  return (
    <div className="   w-full  md:h-20 h-16 flex justify-center items-center   fixed  object-cover    bottom-0 bg-black ">
      <div className=" container px-0 md:px-0 w-screen mx-auto flex items-center justify-around">
        <div className=" text-center">
          <Link to="/home" className="" onClick={() => setNavbar("/")}>
            <IoHome
              size={30}
              className="m-0 p-0 ml-1"
              color="#fff"
            />
            <h3 className=" font-semibold  sm:text-base text-xs  text-[#fff]">Home</h3>
          </Link>
        </div>
        <div className="text-center">
          <Link to="/history" onClick={() => setNavbar("/market")}>
            <BsClipboardCheckFill
              className=" ml-2 mb-1"
              size={25}
               color="white"
            />
            <h3 className=" font-semibold text-[#fff]  sm:text-base text-xs ">History</h3>
          </Link>
        </div>

        <div className="">
          <Link to="#" onClick={() => setNavbar("/my_nfts")}>
            <MdOutlineSupportAgent
              className=" text-center ml-2"
              size={35}
               color="white"
            />

            <h3 className=" font-semibold text-[#fff]  sm:text-base text-xs">Member</h3>
          </Link>
        </div>
        <div className="">
          <Link to="/profile" onClick={() => setNavbar("/profile")}>
            <FaUserAlt
              className=" text-center ml-1"
              size={30}
               color="white"
            />

            <h3 className=" font-semibold pt-1 text-[#fff]  sm:text-base text-xs">Profile</h3>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BottomNavbar;
