import React from "react";
import Navbar from "../../components/navbar/Navbar";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";

const Promotion = () => {
  return (
    <div>
      <Navbar />
      <div className=" h-[70vh] ">
        <img
          src={require("../../assets/image/ARTICLE.png")}
          alt=""
          className=" w-full h-full object-cover"
        />
      </div>

      <div className="  container mx-auto py-8 md:px-12 px-6 bg-transparent border rounded-md mt-8 shadow-xl border-white border-opacity-20 backdrop-blur-md mb-28">
        <div>
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
            Harbor capital club Agency Cooperation
          </h2>

          <p className=" text-white pt-5">
            Harbor capital club allows members to earn rewards by inviting
            friends and family to join. Providea unique Harbor initation code
            for others, allowing them to receive additional revenue and
            inviation rewards from the invitees' comission.
          </p>
        </div>

        <div className=" pt-6">
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
            Invitation qualifications
          </h2>
          <ul className=" p-0 pt-3">
            <li className=" text-white">
              The invites must be a number of the Harbor platform{" "}
            </li>
            <li className=" text-white">
              Invites must fully comply with all terms and conditions in order
              to participate in the Harbor agency cooperation program{" "}
            </li>
          </ul>
        </div>
        <div className=" pt-6">
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
            Basic information
          </h2>

          <p className=" text-white pt-5">
            When relatives and friends use your inviation code to register for a
            Harbor account, it means that you have participate in the Harbor
            angency cooperation program, and conditions, as well as the decision
            and interpretation of Harbor. These decisions and interpretation are
            final and binding on all matters relating to the plan.
          </p>
        </div>

        <div className=" pt-6">
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
            Invite
          </h2>
          <ul className=" p-0 pt-3">
            <li className=" text-white">
              The inviation code can be used to scan the code when relatives and
              frinds register. Relatives and friends scan the invite's inviation
              code to become the main agent of the invite.
            </li>
          </ul>
        </div>
        <div className=" pt-6">
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
            Reward method
          </h2>
          <ul className=" p-0 pt-3">
            <li className=" text-white">
              The reward depends on the profit amount of the invited relatives
              and friends, and the relative position of the invites in the
              agency progarm determines the reward commission you can get
            </li>
          </ul>
        </div>

        <table className="table mt-5 md:w-96 w-full overflow-hidden text-center border">
          <thead className=" bg-yellow-400  rounded-tl-lg rounded-tr-lg">
            <td className=" py-1 font-medium text-white ">Level</td>
            <td className="py-1 font-medium text-white">Percentage</td>
          </thead>
          <tbody className=" bg-yellow-100">
            <td className="py-1 font-medium">Level 1</td>
            <td className="py-1 font-medium">12%</td>
            
          </tbody>
          <tbody className=" bg-yellow-100">
            <td className="py-1 font-medium">Level 2</td>
            <td className="py-1 font-medium">6%</td>
            
          </tbody>
          <tbody className=" bg-yellow-100">
            <td className="py-1 font-medium">Level 3</td>
            <td className="py-1 font-medium">3%</td>
            
          </tbody>
          <tbody className=" bg-yellow-100">
            <td className="py-1 font-medium">Level 4</td>
            <td className="py-1 font-medium">1.5%</td>
            
          </tbody>
        </table>

        <div className=" pt-6">
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
            Remark
          </h2>

          <ul className=" p-0 pt-3 ">
            <li className=" text-white">
              1. All personal bounses generated by participate in the Harbor
              promotion program are paid by Harbor and will not affect the
              memeber's income.
            </li>
            <li className=" text-white">
              2. inviting one person will result in commission rewards being
              calculated and automatically distributed by the system.
            </li>
          </ul>
        </div>

        <div className=" p-5  rounded-md flex flex-col bg-black mt-4 md:w-[500px] w-[100%]">
          <span className="text-white font-medium text-md">
            1. A invites B to recharge 100 USDT, rewarding A with 12 USDT.
          </span>
          <span className="text-white font-medium text-md">
            2. B invites C to recharge 100 USDT, rewarding A with 6 USDT.
          </span>
          <span className="text-white font-medium text-md">
            2. C invites D to recharge 100 USDT, rewarding A with 3 USDT.
          </span>
          <span className="text-white font-medium text-md">
            2. D invites E to recharge 100 USDT, rewarding A with 1.5 USDT.
          </span>
          <span className="text-white font-medium pt-4 text-md">
            3. Rewards are automatically credied within 5-10 minutes.
          </span>
        </div>

        <div>
          {/* <img src={require('../../assets/image/illustration-making.png')}   width={'20px'} alt="" /> */}
        </div>

        <div className=" pt-6">
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
            Disclaimer
          </h2>

          <p className=" text-white pt-5">
            The Commission and reward obtained by the invited members with be
            distributed to the account of the inviter every day. invites can
            view the commissions and rewards in fund details.
          </p>
        </div>

        {/* <img src={require('../../assets/image/invite.png')} alt="" /> */}
      </div>

      <BottomNavbar />
    </div>
  );
};

export default Promotion;
