import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";
import Modal from "../../components/MyModal/MyModal";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const Order_Grab_Rules = () => {
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("harbor_user_id"));
  const [users, setUsers] = useState([]);
  const [depoist, setDepoist] = useState([]);
  const [referralEarning, setReferralEarnings] = useState(null);
  const [todayProfit, setTodayProfit] = useState([]);
  const [orderGrab,setOrderGrab] = useState([])
  console.log(id,'======================');
  useEffect(() => {
    axios
      .get(`${Base_url}/apis/user/get/${user?._id}`)
      .then((res) => {
        console.log(res.data);
        setUsers(res.data.data);
      })
      .catch((error) => {});

      axios
      .get(`${Base_url}/apis/referralEarning/getUser/${user?._id}`)
      .then((res) => {
        console.log(res.data, "referral earning....");

        setReferralEarnings(res.data.earnings);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/apis/deposit/user/${user?._id}/null/null`)
      .then((res) => {
        console.log(res, "depoist");
        setDepoist(res.data.data);


        const saveFilter = res.data.data.filter((i) => {
          return i.verify === true;
        });

        const save = saveFilter?.map((product) => product.amount);
        const totalDepositBonus = save?.reduce(
          (accumulator, currentValue) =>
            parseInt(accumulator) + parseInt(currentValue),
          0
        );

        setDepoist(totalDepositBonus);
        console.log(
          "=========Total Purchase Price===========",
          totalDepositBonus
        );


      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/apis/grab/today/${user?._id}`)
      .then((res) => {
        console.log(res, "today profit");
        setTodayProfit(res.data);
      })
      .catch((error) => {});



      axios
      .get(`${Base_url}/apis/grab/getUser/${user?._id}`)
      .then((res) => {
        console.log(res.data);
        setOrderGrab(res.data.data);
      })
      .catch((error) => {});


      
  }, []);




  const grabTotal = orderGrab?.map((product) => product.amount);
  const totalGrabOrder = grabTotal?.reduce(
    (accumulator, currentValue) =>
      parseFloat(accumulator) + parseFloat(currentValue),
    0
  );

  console.log("=========Total Purchase Price===========", totalGrabOrder);



  // const save = depoist?.map((product) => product.amount);
  // const totalDepositBonus = save?.reduce(
  //   (accumulator, currentValue) =>
  //     parseInt(accumulator) + parseInt(currentValue),
  //   0
  // );

  // console.log("=========Total Purchase Price===========", totalDepositBonus);

  const finalData = parseFloat(
    users?.wallet === undefined || users?.wallet === null
      ? 0
      : parseFloat(users?.wallet) + depoist
  ).toFixed(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [grabSuccess, setGrabSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [getResult, setGetResult] = useState({});

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleGrabNow = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setGrabSuccess(true);
      setTimeout(async () => {
        try {
          const params = { userId: user?._id, vipPlan: `vip${id}` };
          const res = await axios.post(`${Base_url}/apis/grab/create`, params);
          console.log(res, "grab ==============???????????????=====");
          if (res?.data?.status === "ok") {
            setIsModalOpen(true);
            setSubmitted(true);
            setGetResult(res?.data?.data);

            setTimeout(() => {
              setSubmitted(false);
              setIsModalOpen(false);
              toast.success("Order Successfully!");
            }, 2000);

            axios
              .get(`${Base_url}/apis/grab/today/${user?._id}`)
              .then((res) => {
                console.log(res, "today profit");
                setTodayProfit(res.data);
              })
              .catch((error) => {});

              axios
              .get(`${Base_url}/apis/grab/getUser/${user?._id}`)
              .then((res) => {
                console.log(res.data);
                setOrderGrab(res.data.data);
              })
              .catch((error) => {});


          } else {
            toast.error(res?.data?.message);
          }
        } catch (error) {
          console.error("Error during grab creation", error);
          toast.error(
            error?.response?.data?.message || "An unexpected error occurred."
          );
        } finally {
          setGrabSuccess(false);
        }
      }, 2000);
    }, 2000);
  };
  const [loading, setLoader] = useState(false);

  return (
    <div>
      <Navbar />

      {isLoading === true ? (
        <div className=" fixed z-50 inset-0 flex justify-center items-center">
          <div className=" flex flex-col bg-[rgba(0,0,0,0.8)]  w-36 p-4 rounded-lg ">
            <div className="inline-block mx-auto w-[40px] h-[40px] rounded-full border-[3px] border-t-black/20 border-r-black/20 animate-spin"></div>
            <span className=" text-white text-sm text-center pt-2">
              Wating for the response of the product search system...
            </span>
          </div>
        </div>
      ) : null}

      {grabSuccess === true ? (
        <div className=" fixed z-50 inset-0 flex justify-center items-center">
          <div className=" flex flex-col bg-[rgba(0,0,0,0.8)]  w-36 p-4 rounded-lg ">
            <div className="inline-block mx-auto w-[40px] h-[40px] rounded-full border-[3px] border-t-black/20 border-r-black/20 animate-spin"></div>
            <span className=" text-white text-sm text-center pt-2">
              Order distribution in progress
            </span>
          </div>
        </div>
      ) : null}

      {submitted === true ? (
        <div
          style={{ zIndex: "99999999" }}
          className=" fixed  inset-0 flex justify-center items-center"
        >
          <div className=" flex flex-col bg-[rgba(0,0,0,0.4)]  w-36 p-4 rounded-lg ">
            <div className="inline-block mx-auto w-[40px] h-[40px] rounded-full border-[3px] border-t-black/20 border-r-black/20 animate-spin"></div>
            <span className=" text-white text-sm text-center pt-2">
              Submitted...
            </span>
          </div>
        </div>
      ) : null}

      <div className=" w-[55%] my-10 mb-32 mx-auto">
        <div>
          <div className=" bg-transparent border bg-black rounded-md p-4  border-gray-50 border-opacity-20 backdrop-blur-md ">
            <div className=" flex flex-col">
              <span className="  text-white text-lg font-semibold">
                Get the order
              </span>
              <span className=" text-sm text-white">
                Click "Grab now" button to get order
              </span>
            </div>
            <div
              className={`grid grid-cols-5  h-36 overflow-hidden  gap-4 bg-BG   rounded-md p-2.5 my-4 ${
                grabSuccess ? "scrolling-container" : ""
              } `}
            >
              {Array.from({ length: 15 }).map((_, index) => (
                <div
                  key={index}
                  className={`flex flex-col w-28 h-28 justify-center items-center bg-black rounded-md ${
                    grabSuccess ? "scrolling" : ""
                  }`}
                >
                  <img src={require("../../assets/image/logo.png")} alt="" />
                </div>
              ))}
            </div>
            <div className=" p-3">
              <span className=" text-center text-sm text-white">
                Order grabbing...the result will be shown below.
              </span>
            </div>
          </div>
          <button
            onClick={handleGrabNow}
            disabled={isLoading}
            className=" mt-4  bg-[#AF7B2B] font-semibold text-white w-full py-2.5 rounded-md"
          >
            {isLoading ? "Grabbing…" : "Grab now"}
          </button>
        </div>

        <div>
          <p className=" text-white py-3">Result today</p>
          <div className=" bg-transparent border bg-black rounded-md p-4 border-gray-50  border-opacity-20 backdrop-blur-md ">
            <div className=" flex flex-col">
              <span className=" text-sm  text-white">My total assets</span>
              <span className=" text-white">{finalData}</span>
            </div>
            <div className="  flex justify-between  pt-4 items-center">
              <div className=" flex flex-col">
                <span className=" text-sm  text-white">Grabbed/Total</span>
                <span className=" text-sm  text-white">
                  {" "}
                  {todayProfit?.data === null || todayProfit?.data === undefined
                    ? 0
                    : todayProfit?.data}{" "}
                  / 7
                </span>
              </div>
              <div className=" flex flex-col">
                <span className=" text-sm  text-white">Promotion bouns</span>
                <span className=" text-sm  text-white">
                  {/* {(totalGrabOrder).toFixed(2)} */}
                  {referralEarning == null || undefined ? 0 : referralEarning}
                </span>
              </div>
              <div className=" flex flex-col">
                <span className=" text-sm  text-white">Today's profits</span>
                <span className=" text-sm  text-white">
                  {todayProfit?.earnings === null ||
                  todayProfit?.earnings === undefined
                    ? 0
                    : (todayProfit?.earnings).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        className={" md:w-[50%] rounded-lg w-[100%]"}
      >
        {/* Modal Content */}
        <div className="">
          <div className=" bg-BG p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize  text-white h4">
              Order-grabbed successfully
            </h1>
            <div></div>
            {/* <MdClose onClick={closeModal} size={25} color="white" /> */}
          </div>
          <hr />
          <div className=" p-5">
            <div className=" flex flex-col gap-5">
              <div className=" bg-black border  border-opacity-20 border-gray-50 rounded-lg py-3">
                <div className=" bg-[#2F2F2F] flex  items-center gap-4 p-3">
                  <img
                    src={require("../../assets/image/question-mark-gold.avif")}
                    className=" w-10 h-10"
                    alt=""
                  />
                  <span className=" text-white">3.23 x 1</span>
                </div>
                <ul className="p-3.5   leading-8">
                  <li className=" flex ">
                    <span className=" text-white w-44">Order number</span>
                    <span className=" text-white text-sm">
                      {getResult?._id}
                    </span>
                  </li>
                  <li className=" flex ">
                    <span className=" text-white w-44">Order time</span>
                    <span className=" text-white text-sm">
                      {getResult?.createdAt}
                    </span>
                  </li>
                  <li className=" flex ">
                    <span className=" text-white w-44">Order total</span>
                    <span className=" text-white text-sm">
                      ${" "}
                      {parseFloat(
                        users?.wallet === undefined || users?.wallet === null
                          ? 0
                          : parseFloat(users?.wallet) + depoist
                      ).toFixed(1)}
                    </span>
                  </li>
                  <li className=" flex">
                    <span className=" text-white w-44">Commission free</span>
                    <span className=" text-white text-sm">
                      $ {parseFloat(getResult?.amount).toFixed(2)}
                    </span>
                  </li>
                </ul>
              </div>

              <div className=" justify-between items-center bg-[#0B0B0B] border-gray-50  border-opacity-20  border rounded-md flex px-4 py-2.5">
                <span className=" text-white">Expect to return</span>
                <span className=" text-red-600  font-semibold text-lg">
                  ${" "}
                  {parseFloat(
                    users?.wallet === undefined || users?.wallet === null
                      ? 0
                      : parseFloat(users?.wallet) + depoist
                  ).toFixed(2) - parseFloat(getResult?.amount).toFixed(2)}
                </span>
              </div>
              <div>
                <button
                  type="submit"
                  className="  bg-black border-2 border-[#AF7B2B] text-[#AF7B2B] w-full rounded-md py-3  font-medium"
                >
                  Submit Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <BottomNavbar />
    </div>
  );
};

export default Order_Grab_Rules;
