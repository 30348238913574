import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../MyModal/MyModal";
import { MdClose } from "react-icons/md";
import toast from "react-hot-toast";
import { FaImage } from "react-icons/fa";
import axios from "axios";
import { Base_url } from "../../utils/Base_url";
import { fetchToken, onMessageListener } from "../../firebase";

const Category = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("harbor_user_id"));
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const [isModalOpen3, setIsModalOpen3] = useState(false);

  const openModal3 = () => {
    setIsModalOpen3(true);
  };

  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const [isModalOpen4, setIsModalOpen4] = useState(false);

  const openModal4 = () => {
    setIsModalOpen4(true);
  };

  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  const [isModalOpen5, setIsModalOpen5] = useState(false);

  const openModal5 = () => {
    setIsModalOpen4(true);
  };

  const closeModal5 = () => {
    setIsModalOpen4(false);
  };

  const navigate = useNavigate();

  const [users, setUsers] = useState({});
  const [userName, setUserName] = useState([]);
  const [referralEarning, setReferralEarnings] = useState(null);
  const [todayProfit, setTodayProfit] = useState([]);
  console.log(userName);

  const [depoist, setDepoist] = useState(0);

  useEffect(() => {
    axios
      .get(`${Base_url}/apis/user/get/${user?._id}`)
      .then((res) => {
        // console.log(res.data);
        setUsers(res.data.data);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/apis/deposit/user/${user?._id}/null/null`)
      .then((res) => {
        // console.log(res, "depoist");
        // setDepoist(res.data.data);

        const saveFilter = res.data.data.filter((i) => {
          return i.verify === true;
        });

        const save = saveFilter?.map((product) => product.amount);
        const totalDepositBonus = save?.reduce(
          (accumulator, currentValue) =>
            parseInt(accumulator) + parseInt(currentValue),
          0
        );

        setDepoist(totalDepositBonus);
        // console.log(
        //   "=========Total Purchase Price===========",
        //   totalDepositBonus
        // );
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/apis/referralEarning/today/${user?._id}`)
      .then((res) => {
        // console.log(res.data, "today");
        setUserName(res.data);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/apis/referralEarning/getUser/${user?._id}`)
      .then((res) => {
        // console.log(res.data, "referral earning....");

        setReferralEarnings(res.data.earnings);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/apis/grab/today/${user?._id}`)
      .then((res) => {
        // console.log(res, "today profit");
        setTodayProfit(res.data);
      })
      .catch((error) => {});
  }, []);

  console.log(users);
  console.log(users?.deposit === true);
  const subFun = () => {
    if (!users?.deposit === true) {
      toast.error("Please depoist with your wallet ");
    } else {
      navigate("/invite");
    }
  };

  const [selectedImage, setSelectedImage] = useState(null);
  console.log(selectedImage);
  const [selectImages, setSelectedImages] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedImages(file);

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [state, setState] = useState({
    amount: "",
    depositMethod: "Virtual currency deposit",
    currency: "",
    channel: "",
    image: "",
  });

  useEffect(() => {
    if (users?.walletAddress) {
      setWalletAddress(users.walletAddress);
    }
  }, [users?.walletAddress]);

  const [walletAddress, setWalletAddress] = useState("");
  const handleInputs = (e) => {
    const { name, value } = e.target;

    // Check if depositMethod is being changed
    if (name === "depositMethod") {
      let currencyValue = "";
      let channelValue = "";

      if (value === "Virtual currency deposit") {
        currencyValue = "TRC20"; // Set currency to TRC20 for physical currency deposit
        channelValue = "TRC20-TRC202"; // Set channel for virtual currency deposit
      } else if (value === "Physical currency deposit") {
        currencyValue = "USDT"; // Set currency to USDT for virtual currency deposit
        channelValue = "TRC20"; // Set channel for physical currency deposit
      }

      // Update depositMethod, currency, and channel state
      setState({
        ...state,
        [name]: value,
        currency: currencyValue,
        channel: channelValue,
      });
    } else {
      // Update state normally for other fields
      setState({ ...state, [name]: value });
    }
  };

  const [loading, setLoader] = useState(false);

  const handleSubmit = (e) => {
    console.log(e);

    e.preventDefault();

    if (state.amount.length === 0) {
      toast.error("Please enter amount");
    } else if (state.amount < 50) {
      toast.error("Depoist amount must be grater equal 50");
    } else if (!selectedImage) {
      toast.error("Please upload your transcation image");
    } else {
      setLoader(true);
      const params = new FormData();
      params.append("userId", user?._id);
      params.append("amount", state?.amount);
      params.append("depositMethod", state?.depositMethod);
      params.append("currency", state?.currency);
      params.append("channel", state?.channel);
      params.append("image", selectImages);

      axios
        .post(`${Base_url}/apis/deposit/create`, params)
        .then((res) => {
          console.log(res);
          if (res?.data?.status === "ok") {
            setLoader(false);
            toast.success("Please wait until your payment  verify by admin!");
            setIsModalOpen2(false);

            axios
              .get(`${Base_url}/apis/user/get/${user?._id}`)
              .then((res) => {
                // console.log(res);
                setUsers(res.data.data);
              })
              .catch((error) => {});

            // const params = new FormData();

            // params.append("walletAddress", "TMuEh3kf7D6oYfxZWw4wDKMywoSEhvpGGS");

            // axios
            //   .put(`${Base_url}/apis/user/update/${user?._id}`, params)
            //   .then((res) => {
            //     console.log(res);
            //     // setUsers(res.data.data);
            //   })
            //   .catch((error) => {});
          } else {
            toast.error(res?.data?.message);
            navigate("/login");
            localStorage.removeItem("harbor_user_id");
          }
        })
        .catch((error) => {
          // console.log(error);

          if (error?.response?.data?.status === "fail") {
            toast.error(error?.response?.data?.message);
            setLoader(false);
          } else {
          }
        });
    }
  };

 

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setShow(true);
      console.log({
        title: payload.notification.title,
        body: payload.notification.body,
      });

      const title = payload.notification.title;
      const body = payload.notification.body;
      const message = `${title}: ${body}`;

      toast.success(message, {
        duration: 4000, // Duration in milliseconds
      });

      // addNotification({
      //   title: "Warning",
      //   subtitle: "This is a subtitle",
      //   message: "This is a very long message",
      //   theme: "darkblue",
      //   native: true,
      // });


      axios
        .get(`${Base_url}/apis/user/get/${user?._id}`)
        .then((res) => {
        
          setUsers(res.data.data);
        })
        .catch((error) => {});

      axios
        .get(`${Base_url}/apis/deposit/user/${user?._id}/null/null`)
        .then((res) => {
        

          const saveFilter = res.data.data.filter((i) => {
            return i.verify === true;
          });

          const save = saveFilter?.map((product) => product.amount);
          const totalDepositBonus = save?.reduce(
            (accumulator, currentValue) =>
              parseInt(accumulator) + parseInt(currentValue),
            0
          );

          setDepoist(totalDepositBonus);
         
        })
        .catch((error) => {});

      axios
        .get(`${Base_url}/apis/referralEarning/today/${user?._id}`)
        .then((res) => {
        
          setUserName(res.data);
        })
        .catch((error) => {});
    })
    .catch((err) => console.log("failed: ", err));

  const onShowNotificationClicked = () => {
    setNotification({
      title: "Notification",
      body: "This is a test notification",
    });
    setShow(true);
  };

  const [withdrawal, setWithdrawal] = useState({
    amount: "",
    withdrawPassword: "",
    depositMethod: "Virtual currency deposit",
    currency: "",
    channel: "",
  });

  const [amountWithdraw, setAmountWithdraw] = useState("");

  const handleWithdrawInputs = (e) => {
    const { name, value } = e.target;

    if (name === "depositMethod") {
      let currencyValue = "";
      let channelValue = "";

      if (value === "Virtual currency deposit") {
        currencyValue = "TRC20";
        channelValue = "TRC20-TRC202";
      } else if (value === "Physical currency deposit") {
        channelValue = "TRC20";
        currencyValue = "USDT";
      }

      setWithdrawal({
        ...state,
        [name]: value,
        currency: currencyValue,
        channel: channelValue,
      });
    } else {
      setWithdrawal({ ...state, [name]: value });
    }
  };

  const WithdrawSubmit = (e) => {
    console.log(e);

    e.preventDefault();

    if (!withdrawal?.withdrawPassword?.length) {
      toast.error("Please enter withdrawal password!");
    } else if (withdrawal?.withdrawPassword !== user?.withdrawPassword) {
      toast.error("invalid password!");
    } else {
      setLoader(true);
      const params = {
        userId: user?._id,
        withdrawAmount: Number(amountWithdraw),
        depositMethod: withdrawal?.depositMethod,
        currency: withdrawal?.currency,
        channel: withdrawal?.channel,
        walletAddress: walletAddress,
        verify: false,
      };
      axios
        .post(`${Base_url}/apis/withdraw/create`, params)
        .then((res) => {
          
          if (res?.data?.status === "ok") {
            setLoader(false);
            toast.success("Please wait until your payment  verify by admin!");
            setIsModalOpen5(false);

            axios
              .get(`${Base_url}/apis/user/get/${user?._id}`)
              .then((res) => {
            
                setUsers(res.data.data);
              })
              .catch((error) => {});

            const params = new FormData();

            params.append("walletAddress", walletAddress);

            axios
              .put(`${Base_url}/apis/user/update/${user?._id}`, params)
              .then((res) => {
               
              })
              .catch((error) => {});
          } else {
            setLoader(false);
            toast.error(res?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error);

          if (error?.response?.data?.status === "fail") {
            toast.error(error?.response?.data?.message);
            setLoader(false);
          } else {
          }
        });
    }
  };

  return (
    <>
      <div className=" container mx-auto my-7 px-7">
        <div className=" bg-BG  bg-yellow-600 p-6 rounded-md w-full">
          <div className=" md:flex  block  justify-between py-6">
            <div className="">
              <span className="  font-semibold text-white">Total Balance</span>
              <div>
                <span className=" text-white font-semibold text-xl pt-4">
                  $
                  {parseFloat(
                    users?.wallet === undefined || users?.wallet === null
                      ? 0
                      : parseFloat(users?.wallet) + depoist
                  ).toFixed(1)}
                </span>
              </div>
            </div>
            <hr className=" my-2" />
            <div className=" md:flex block  gap-8">
              <div className="pt-4">
                <span className=" text-white font-semibold text-xl pt-4">
                  {todayProfit?.earnings === null ||
                  todayProfit?.earnings === undefined
                    ? 0
                    : (todayProfit?.earnings).toFixed(2)}
                </span>
                <p className="  font-semibold text-white">Daily Profit</p>
              </div>
              <div className="pt-4">
                <span className=" text-white font-semibold text-xl pt-4">
                  {/* ${userName?.earnings === undefined || null ? 0 : userName?.earnings} */}
                  $
                  {parseFloat(
                    userName?.earnings === undefined || null
                      ? 0
                      : userName?.earnings
                  ).toFixed(1)}
                  {/* ${users?.wallet === undefined || null ? 0 : users?.wallet} */}
                </span>
                <p className="  font-semibold text-white">Daily Earnings</p>
              </div>
              <div className="pt-4">
                <span className=" text-white font-semibold text-xl pt-4">
                  {/* $
                  {users?.depositBonus === undefined || null
                    ? 0
                    : users?.depositBonus} */}
                  $
                  {parseFloat(
                    users?.depositBonus === undefined || null
                      ? 0
                      : users?.depositBonus
                  ).toFixed(1)}
                </span>
                <p className="  font-semibold text-white">Deposit bonus</p>
              </div>
              <div className="pt-4">
                <span className=" text-white font-semibold text-xl pt-4">
                  {/* $ */}
                  {/* {users?.wallet === undefined || null
                    ? 0
                    : users?.wallet - users?.depositBonus} */}
                  ${referralEarning == null || undefined ? 0 : referralEarning}
                </span>
                <p className="  font-semibold text-white">referral bonus</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" container py-8 md:px-12   text-center gap-12 mx-auto grid xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-3 grid-cols-2">
        <div onClick={openModal} className=" cursor-pointer">
          <img
            src={require("../../assets/image/deposit.png")}
            className="  mx-auto"
            alt=""
          />
          <span className="  text-white"> Deposit</span>
        </div>
        <div onClick={openModal3} className=" cursor-pointer">
          <img
            src={require("../../assets/image/wallet.png")}
            className=" mx-auto"
            alt=""
          />
          <span className="  text-white"> Withdrawal</span>
        </div>
        <div onClick={subFun} className=" cursor-pointer">
          <img
            src={require("../../assets/image/users.png")}
            className=" mx-auto"
            alt=""
          />
          <span className="  text-white"> Invite friends</span>
        </div>
        <div className=" cursor-pointer">
          <img
            src={require("../../assets/image/giftbox.png")}
            className=" mx-auto"
            alt=""
          />
          <span className="  text-white">Event</span>
        </div>{" "}
        <Link to={"/about_us"}>
          <img
            src={require("../../assets/image/group.png")}
            className=" mx-auto"
            alt=""
          />
          <span className="  text-white"> About us</span>
        </Link>
        <Link to={"/rule_description"}>
          <img
            src={require("../../assets/image/auction.png")}
            className="mx-auto"
            alt=""
          />
          <span className="  text-white">Rule description</span>
        </Link>
        <Link to={"/promotion"}>
          <img
            src={require("../../assets/image/marketing.png")}
            className=" mx-auto"
            alt=""
          />
          <span className="  text-white">Promotion description</span>
        </Link>
        <Link to={"/vip"}>
          <img
            src={require("../../assets/image/vip.png")}
            className="mx-auto"
            alt=""
          />
          <span className="   text-white">VIP</span>
        </Link>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        className={" md:w-[60%] w-[100%]"}
      >
        {/* Modal Content */}
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize  text-white h4">Deposit</h1>
            <MdClose onClick={closeModal} size={25} color="white" />
          </div>
          <hr />
          <div className=" p-5">
            <div className=" flex flex-col gap-5">
              <div>
                <label className=" pb-2 text-white font-medium">
                  Deposit method
                </label>
                <select
                  onChange={handleInputs}
                  value={state.depositMethod}
                  name="depositMethod"
                  className=" w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                >
                  <option
                    value={"  Virtual currency deposit"}
                    className="transparent-option"
                  >
                    Virtual currency deposit
                  </option>
                  <option
                    value={"Physical currency deposit"}
                    className="transparent-option text-black"
                  >
                    Physical currency deposit
                  </option>
                </select>
              </div>

              <div>
                <label className=" pb-2 text-white font-medium">Currancy</label>
                <select
                  onChange={handleInputs}
                  value={state.currency}
                  name="currency"
                  className=" w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                >
                  <option className="transparent-option">USDT</option>
                  <option className="transparent-option">TRC20</option>
                </select>
              </div>
              <div>
                <label className=" pb-2 text-white font-medium">
                  Deposit Channcel
                </label>
                <select
                  onChange={handleInputs}
                  value={state.channel}
                  name="channel"
                  className=" w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                >
                  <option className="transparent-option">TRC-20</option>
                  <option className="transparent-option">TRC-20</option>
                </select>
              </div>
              {/* <div className="">
                <label className=" pb-2 text-white font-medium">
                  Wallet Address
                </label>

                <input
                  onChange={(e) => setWalletAddress(e.target.value)}
                  name="walletAddress"
                  type="text"
                  placeholder="Please enter address"
                  defaultValue={users?.walletAddress}
                  className="w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                />
              </div> */}
              <div>
                <button
                  onClick={() => {
                    // if (!walletAddress) {
                      // toast.error("Please enter your wallet address!");
                    // } else {
                      setIsModalOpen2(true);
                      setIsModalOpen(false);
                    // }
                  }}
                  type="submit"
                  className=" bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                >
                  Next step
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen2}
        onClose={closeModal2}
        className={" md:w-[60%] w-[100%]"}
      >
        {/* Modal Content */}
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize  text-white h4">Deposit</h1>
            <MdClose onClick={closeModal2} size={25} color="white" />
          </div>
          <hr />
          <form className=" p-5">
            <div className=" flex flex-col gap-5">
              <div>
                <label className=" pb-2 text-white font-medium">
                  Deposit amount
                </label>

                <input
                  onChange={handleInputs}
                  value={state.amount}
                  name="amount"
                  placeholder="Please enter"
                  className="w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                />

                <div className=" float-right pt-3">
                  {selectedImage ? (
                    <label
                      htmlFor="fileInput"
                      className=" rounded-md  border overflow-hidden flex w-28  h-28"
                    >
                      <img
                        src={selectedImage}
                        className="  object-cover w-full h-full"
                        alt=""
                      />
                      <input
                        accept="image/*"
                        onChange={handleFileChange}
                        name="images"
                        type="file"
                        id="fileInput"
                        className="hidden"
                      />
                    </label>
                  ) : (
                    <label
                      htmlFor="fileInput"
                      className="bg-[#FEFBFB] border rounded-md p-1 w-40 flex  justify-center items-center"
                    >
                      <div>
                        <div className=" w-28 h-28  flex justify-center items-center bg-transparent border-2 border-white border-opacity-20 backdrop-blur-md ">
                          <FaImage size={60} className=" text-[#AF7B2B]" />
                        </div>
                        <input
                          accept="image/*"
                          onChange={handleFileChange}
                          name="images"
                          type="file"
                          id="fileInput"
                          className="hidden"
                        />
                      </div>
                    </label>
                  )}
                  <span className="  mt-3 text-white">Transaction Upload</span>
                </div>
              </div>

              <ul className="p-0">
                <li className=" text-white">
                  1. Please provide the required info above to complete the
                  payment
                </li>
                <li className=" text-white">
                  2.Validate the amount to ensure it falls within the specified
                  limit (1 - 1,000,000)
                </li>
                <li className=" text-white">
                  3. Use the provided account number (  <span className=" text-gray-400">TNmJxEiiFJmGmk4qoEb9PYeybtCHXnbHbZ</span> ) for the payment
                  destination
                </li>
                <li className=" text-white">
                  4. After completing the payment, take a screenshot of the
                  confirmation screen.{" "}
                </li>
              </ul>
              <div>
                {loading === true ? (
                  <button
                    disabled
                    type="button"
                    class="bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className=" bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                  >
                    Go Pay
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen3}
        onClose={closeModal3}
        className={" md:w-[60%] w-[100%]"}
      >
        {/* Modal Content */}
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize  text-white h4">Withdrawal</h1>
            <MdClose onClick={closeModal3} size={25} color="white" />
          </div>
          <hr />
          <div className=" p-5">
            <div className=" flex flex-col gap-5">
              <div>
                <label className=" pb-2 text-white font-medium">
                  Withdraw method
                </label>
                <select
                  onChange={handleWithdrawInputs}
                  value={withdrawal.depositMethod}
                  name="depositMethod"
                  className=" w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                >
                  <option
                    value={"  Virtual currency deposit"}
                    className="transparent-option"
                  >
                    Virtual currency Withdraw
                  </option>
                  <option
                    value={"Physical currency deposit"}
                    className="transparent-option text-black"
                  >
                    Physical currency Withdraw
                  </option>
                </select>
              </div>

              <div>
                <label className=" pb-2 text-white font-medium">Currancy</label>
                <select
                  onChange={handleWithdrawInputs}
                  value={withdrawal.currency}
                  name="currency"
                  className=" w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                >
                  <option className="transparent-option">USDT</option>
                  <option className="transparent-option">TRC20</option>
                </select>
              </div>
              <div>
                <label className=" pb-2 text-white font-medium">
                  Withdraw Channcel
                </label>
                <select
                  onChange={handleWithdrawInputs}
                  value={withdrawal.channel}
                  name="channel"
                  className=" w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                >
                  <option className="transparent-option">TRC-20</option>
                  <option className="transparent-option">TRC-20</option>
                </select>
              </div>
              <div className="">
                <label className=" pb-2 text-white font-medium">
                  Wallet Address
                </label>

                <input
                  onChange={(e) => setWalletAddress(e.target.value)}
                  name="walletAddress2"
                  type="text"
                  placeholder="Please enter address"
                  defaultValue={walletAddress}
                  className="w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                />
              </div>
              <div>
                <button
                  onClick={() => {
                    if (!walletAddress) {
                      toast.error("Please enter wallet address!");
                    } else {
                      setIsModalOpen3(false);
                      setIsModalOpen4(true);
                    }
                  }}
                  type="button"
                  className=" bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                >
                  Next step
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen4}
        onClose={closeModal4}
        className={" md:w-[60%] w-[100%]"}
      >
        {/* Modal Content */}
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize  text-white h4">Withdraw to</h1>
            <MdClose onClick={closeModal4} size={25} color="white" />
          </div>
          <hr />
          <div className=" p-5">
            <div className=" flex flex-col gap-5">
              <div>
                <div>
                  <label className=" py-4 text-white font-medium">
                    Balance: $
                    {parseFloat(
                      users?.wallet === undefined || users?.wallet === null
                        ? 0
                        : parseFloat(users?.wallet) + depoist
                    ).toFixed(1)}
                  </label>
                </div>
                <div>
                  <label className=" pb-2 text-white font-medium">
                    Enter the amount for the cash withdrawal
                  </label>

                  <input
                    onChange={(e) => setAmountWithdraw(e.target.value)}
                    value={amountWithdraw}
                    name="amount"
                    type="number"
                    placeholder="Please enter  amount"
                    className="w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                  />
                </div>
              </div>

              <div>
                {loading === true ? (
                  <button
                    disabled
                    type="button"
                    class="bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      if (!amountWithdraw) {
                        toast.error("Please enter amount!");
                      } else {
                        setIsModalOpen4(false);
                        setIsModalOpen5(true);
                      }
                    }}
                    type="button"
                    className=" bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                  >
                    Next Setp
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen5}
        onClose={closeModal5}
        className={" md:w-[60%] w-[100%]"}
      >
        {/* Modal Content */}
        <div className="">
          <div className=" p-3 flex justify-between items-center">
            <div></div>
            <h1 className="capitalize  text-white h4">Withdraw to</h1>
            <MdClose
              onClick={() => setIsModalOpen5(false)}
              size={25}
              color="white"
            />
          </div>
          <hr />
          <form onSubmit={WithdrawSubmit} className=" p-5">
            <div className=" flex flex-col gap-5">
              <div>
                <div>
                  <label className=" py-4 text-white font-medium">
                    Balance: $
                    {parseFloat(
                      users?.wallet === undefined || users?.wallet === null
                        ? 0
                        : parseFloat(users?.wallet) + depoist
                    ).toFixed(1)}
                  </label>
                </div>

                <div className=" mt-3">
                  <label className=" pb-2 text-white font-medium">
                    Enter withdrawal password
                  </label>

                  <input
                    onChange={handleWithdrawInputs}
                    value={withdrawal.withdrawPassword}
                    name="withdrawPassword"
                    type="text"
                    placeholder="Please enter password"
                    className="w-full py-3 rounded-md bg-transparent border-2 text-white px-2 border-white border-opacity-20 backdrop-blur-md"
                  />
                </div>
              </div>

              <div>
                {loading === true ? (
                  <button
                    disabled
                    type="button"
                    class="bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                  >
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 me-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className=" bg-[#AF7B2B] w-full rounded-md py-3 text-white font-medium"
                  >
                    Cash withdrawal
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Category;
