import React from "react";
import { FaTelegram } from "react-icons/fa"; // Importing the Telegram icon from react-icons
import { AiFillCustomerService } from "react-icons/ai";
import { GrUserManager } from "react-icons/gr";
import { SiGoogletagmanager } from "react-icons/si";
import { Link } from "react-router-dom";
const SocialMedia = () => {
  return (
    <div className="flex flex-col gap-6 items-center justify-between z-10 space-y-4 fixed top-20 mt-10 right-4 pl-3  py-4 w-12 rounded-full h-56 bg-black rounded-r-lg">
      <Link
        to="http://t.me/HCC_CS"
        className="text-white relative hover:text-gray-300 tooltip"
      >
        <AiFillCustomerService size="2em" />
        <span className="tooltiptext">Customer Service</span>
      </Link>
      <Link
        to="http://t.me/LucyHCC"
        className="text-white hover:text-gray-300 tooltip"
      >
        <SiGoogletagmanager  size="2em" />
        <span className="tooltiptext">HCC Manager</span>
      </Link>
      <Link
        to="http://t.me/HarborCapitalClub"
        className="text-white hover:text-gray-300 tooltip"
      >
        <FaTelegram size="2em" />
        <span className="tooltiptext">Official Channel</span>
      </Link>
    </div>
  );
};

export default SocialMedia;
