import React, { useState } from "react";

const Tabs = ({ tabs, defaultTab, className }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  return (
    <div>
      <div className="flex bg-transparent border-2 rounded-md border-white border-opacity-20 backdrop-blur-md scroll-container mt-3  productOverflow  overflow-x-auto whitespace-nowrap">
        {tabs.map((tab) => (
          <button
            key={tab.title}
            className={`px-4 py-4 ${className} ${
              activeTab === tab.title
                ? "  w-full text-white bg-BG"
                : "bg-black  text-white  w-full"
            }`}
            onClick={() => setActiveTab(tab.title)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="mt-4">
        {tabs.map((tab) => (
          <div
            key={tab.title}
            className={activeTab === tab.title ? "" : "hidden"}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
