import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import BottomNavbar from '../../components/bottomNavbar/BottomNavbar'

const AboutUs = () => {
  return (
    <div>
        <Navbar/>
        <div className=' h-96 '>
            <img  src={require('../../assets/image/kanchanara.jpg')} alt='' className=' w-full h-full object-cover' />
        </div>

        <div className='  container mx-auto py-8 md:px-12 px-6 bg-transparent border rounded-md mt-8 shadow-xl border-white border-opacity-20 backdrop-blur-md mb-28'>
         

            <div>
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
          Welcome to Harbor Capital Club – Your Gateway to Financial Excellence!
          </h2>

          <p className=" text-white pt-5">
          At Harbor Capital Club, we transcend traditional finance, providing a dynamic platform for investment enthusiasts, entrepreneurs, and visionaries. Our mission is to empower and inspire, transforming financial aspirations into tangible realities.
          </p>
        </div>



        
        <div className=' pt-4'>
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
          *Our Vision:*
          </h2>

          <p className=" text-white pt-5">
          Harbor Capital Club envisions a world where financial opportunities are within reach of everyone. We aim to cultivate a community where members embark on a journey towards financial excellence, unlocking doors to prosperity and success.
          </p>
        </div>


        <div className=' pt-4'>
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
          *What Sets Us Apart:*
          </h2>

          <ul className=' pt-3 leading-8'>
            <li className=' text-white'>1. *Inclusive Community:* We embrace diversity and inclusivity, nurturing an environment where ideas flourish and collaboration thrives.
            </li>

            <li className=' text-white'>2. *Expert Insights:* Gain access to exclusive insights from financial experts, enabling you to make informed decisions and navigate the ever-evolving landscape of wealth creation.
            </li>
            <li className=' text-white'>3. *Innovative Platforms:* Harbor Capital Club introduces cutting-edge tools and platforms designed to enhance your financial experience, making investment strategies more accessible and effective.
            </li>
          </ul>
        </div>



        <div className=' pt-8'>
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
          *Our Approach to Collaboration:*
          </h2>

          <p className=" text-white pt-5">
          Operating on a collaborative model, Harbor Capital Club ensures members benefit from shared insights, strategic partnerships, and a wealth of resources. Whether you're a seasoned investor or exploring financial opportunities for the first time, our platform caters to your unique journey.
          </p>
        </div>


        <div className=' pt-8'>
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
          *Connect and Thrive:*
          </h2>

          <p className=" text-white pt-5">
          Join Harbor Capital Club and connect with a network of like-minded individuals driven by the pursuit of financial success. Our platform facilitates meaningful connections, ensuring every member contributes to and benefits from our vibrant financial ecosystem.
          </p>
        </div>


        <div className=' pt-8'>
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
          *Your Journey Begins Here:*
          </h2>

          <p className=" text-white pt-5">
          Embark on a journey towards financial empowerment with Harbor Capital Club. We're more than just a platform; we're a community that propels you towards your financial goals. Ready to redefine your financial future? Join us today!
          </p>
        </div>


        
        <div className=' pt-8'>
          <h2 className=" font-semibold text-2xl text-white  border-l-4   border-yellow-900  pl-2">
          *Contact US:*
          </h2>

          <p className=" text-white pt-5">
          For inquiries or further information, please reach out to us through our online customer service or connect with us on social media. Your path to financial excellence begins with Harbor Capital Club!
          </p>
        </div>
           
        </div>

        <BottomNavbar/>
    </div>
  )
}

export default AboutUs