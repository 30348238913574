import React, { useEffect, useState } from "react";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import { Link } from "react-router-dom";
import { fetchToken, onMessageListener } from "../../firebase";
import toast from "react-hot-toast";
const NftProduct = () => {
  const [data, setData] = useState([
    {
      id: 1,
      vip: 1,
      amount: "50-99",
      income: "0.735-1.47",
      isEnabled: false,
    },
    {
      id: 2,
      vip: 2,
      amount: "100-199",
      income: "1.47-2.94",
      isEnabled: false,
    },
    {
      id: 3,
      vip: 3,
      amount: "200-499",
      income: "2.94-7.35",
      isEnabled: false,
    },
    {
      id: 4,
      vip: 4,
      amount: "500-999",
      income: "7.35-14.7",
      isEnabled: false,
    },
    {
      id: 5,
      vip: 5,
      amount: "1000-1999",
      income: "14.7-29.4",
      isEnabled: false,
    },
    {
      id: 6,
      vip: 6,
      amount: "2000-4999",
      income: "29.4-73.5",
      isEnabled: false,
    },
    {
      id: 7,
      vip: 7,
      amount: "5000-7999",
      income: "73.5-117.6",
      isEnabled: false,
    },
    {
      id: 8,
      vip: 8,
      amount: "8000-11999",
      income: "117.6-176.7",
      isEnabled: false,
    },
    {
      id: 9,
      vip: 9,
      amount: "12000-14999",
      income: "176.7-220.5",
      isEnabled: false,
    },
    {
      id: 10,
      vip: 10,
      amount: "15000-19999",
      income: "220.5-294",
      isEnabled: false,
    },
    {
      id: 11,
      vip: 11,
      amount: "20000-24999",
      income: "294-367.5",
      isEnabled: false,
    },
    {
      id: 12,
      vip: 12,
      amount: "25000+",
      income: "367.5",
      isEnabled: false,
    },
  ]);

  const user = JSON.parse(localStorage.getItem("harbor_user_id"));

  const [users, setUsers] = useState([]);
  const [depoist, setDepoist] = useState([]);
  useEffect(() => {
    axios
      .get(`${Base_url}/apis/user/get/${user?._id}`)
      .then((res) => {
        console.log(res.data);
        setUsers(res.data.data);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/apis/deposit/user/${user?._id}/null/null`)
      .then((res) => {
        console.log(res, "depoist");
        setDepoist(res.data.data);

        const saveFilter = res.data.data.filter((i) => {
          return i.verify === true;
        });

        const save = saveFilter?.map((product) => product.amount);
        const totalDepositBonus = save?.reduce(
          (accumulator, currentValue) =>
            parseInt(accumulator) + parseInt(currentValue),
          0
        );

        setDepoist(totalDepositBonus);
        console.log(
          "=========Total Purchase Price===========",
          totalDepositBonus
        );
      })
      .catch((error) => {});
  }, [user?._id]);

  const finalData = parseFloat(
    users?.wallet === undefined || users?.wallet === null
      ? 0
      : parseFloat(users?.wallet) + depoist
  ).toFixed(1);

  console.log(finalData);

  useEffect(() => {
    const updatedData = data.map((item) => {
      const [min, max] = item.amount.split("-").map(Number);
      console.log(max);
      console.log(min);
      const isEnabled = finalData >= min && finalData <= max +1;

      console.log(isEnabled, "===============================");
      return { ...item, isEnabled };
    });

    setData(updatedData);
  }, [users, depoist]);

  // const save = depoist?.map((product) => product.amount);
  // const totalDepositBonus = save?.reduce(
  //   (accumulator, currentValue) =>
  //     parseInt(accumulator) + parseInt(currentValue),
  //   0
  // );

  // console.log("=========Total Purchase Price===========", totalDepositBonus);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setShow(true);
      console.log({
        title: payload.notification.title,
        body: payload.notification.body,
      });

      const title = payload.notification.title;
      const body = payload.notification.body;
      const message = `${title}: ${body}`;

      toast.success(message, {
        duration: 4000, // Duration in milliseconds
      });

      // addNotification({
      //   title: "Warning",
      //   subtitle: "This is a subtitle",
      //   message: "This is a very long message",
      //   theme: "darkblue",
      //   native: true,
      // });

      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  const onShowNotificationClicked = () => {
    setNotification({
      title: "Notification",
      body: "This is a test notification",
    });
    setShow(true);
  };

  return (
    <>
      <div className="container w-full md:px-12 px-5 mx-auto py-10">
        <div className=" flex items-center  mt-7 gap-4">
          <div className=" relative">
            <h2 className=" text-white font-bold">Task lobby</h2>
          </div>
        </div>

        <div className="   py-8  w-full  grid  grid-cols-2  xl:grid-cols-4 md:grid-cols-2 gap-5 md:gap-12">
          {data?.map((item, index) => {
            return (
              <>
                {item?.isEnabled ? (
                  <Link
                    to={`/order_grab_rules/${item?.vip}`}
                    className={`relative cursor-pointer`}
                  >
                    <div className="w-full  pt-4 text-black p-2  bg-black   border-2  border-opacity-20 backdrop-blur-md  shadow-xl border-gray-300  rounded-lg group overflow-hidden">
                      <div className="px-3 py-3 flex flex-col gap-1">
                        <p className="text-gray-500 text-base  tracking-wide font-semibold">
                          {item?.title}{" "}
                        </p>
                        <p className="flex items-center gap-2">
                          <span className="text-amazon_light sm:text-lg text-md text-white">
                            Account amount:
                            <span> {item?.amount} </span>
                          </span>
                        </p>
                        <p className="  text-gray-600 text-justify">
                          <span className=" text-md text-white">
                            income: ${item?.income}
                          </span>
                        </p>

                        <img
                          src={require("../../assets/image/logo.png")}
                          className=" w-20 py-3 mx-auto"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="  absolute -top-2 right-0">
                      <img
                        src={require("../../assets/image/gold-ribbon-png.png")}
                        className=" w-24 h-10"
                        alt=""
                      />
                      <span className=" text-black font-semibold text-md  absolute top-0 left-8">
                        VIP {item?.vip}
                      </span>
                    </div>
                  </Link>
                ) : (
                  <div className={`relative  cursor-not-allowed `}>
                    <div className="w-full  text-black p-2   pt-4 bg-transparent border-2  border-opacity-20 backdrop-blur-md  shadow-xl border-gray-300  rounded-lg group overflow-hidden">
                      <div className="px-3 py-3 flex flex-col gap-1">
                        <p className="text-gray-500 text-base  tracking-wide font-semibold">
                          {item?.title}{" "}
                        </p>
                        <p className="flex items-center gap-2">
                          <span className="text-amazon_light sm:text-lg text-md text-white">
                            Account amount:
                            <span> {item?.amount} </span>
                          </span>
                        </p>
                        <p className="  text-gray-600 text-justify">
                          <span className=" text-md text-white">
                            income: ${item?.income}
                          </span>
                        </p>

                        <img
                          src={require("../../assets/image/logo.png")}
                          className=" w-20 py-3 mx-auto"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="  absolute -top-2 right-0">
                      <img
                        src={require("../../assets/image/gold-ribbon-png.png")}
                        className=" w-24 h-10"
                        alt=""
                      />
                      <span className=" text-black font-semibold text-md  absolute top-0 left-8">
                        VIP {item?.vip}
                      </span>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NftProduct;
