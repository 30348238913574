import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Base_url } from "../../utils/Base_url";
import axios from "axios";
import { fetchToken, onMessageListener } from "../../firebase";
import {
  IoNotificationsOffCircleOutline,
  IoNotificationsOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
const Navbar = () => {
  const navigate = useNavigate();
  const [notificationsModal, setNotificationsModal] = useState(false);
  const openModal = () => {
    setNotificationsModal(!notificationsModal);
  };
  const user = JSON.parse(localStorage.getItem("harbor_user_id"));

  const [users, setUsers] = useState([]);
  const [getNotifications, setGetNotifications] = useState([]);

  useEffect(() => {
    axios
      .get(`${Base_url}/apis/user/get/${user?._id}`)
      .then((res) => {
        console.log(res);
        setUsers(res.data.data);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/apis/userNotification/getUser/${user?._id}`)
      .then((res) => {
        console.log(res, "notifications user");

        const unreadNotifications = res.data.data.filter(
          (item) => item.read === false
        );
        setGetNotifications(unreadNotifications);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });

      axios
        .get(`${Base_url}/apis/userNotification/getUser/${user?._id}`)
        .then((res) => {
          console.log(res, "notifications user");

          const unreadNotifications = res.data.data.filter(
            (item) => item.read === false
          );
          setGetNotifications(unreadNotifications);
        })
        .catch((err) => {
          console.log(err);
        });

      setShow(true);
      console.log({
        title: payload.notification.title,
        body: payload.notification.body,
      });

      const title = payload.notification.title;
      const body = payload.notification.body;
      const message = `${title}: ${body}`;

      toast.success(message, {
        duration: 4000, // Duration in milliseconds
      });

      console.log(payload);

      axios
        .get(`${Base_url}/apis/userNotification/getUser/${user?._id}`)
        .then((res) => {
          console.log(res, "notifications user");

          setGetNotifications(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => console.log("failed: ", err));

  const onShowNotificationClicked = () => {
    setNotification({
      title: "Notification",
      body: "This is a test notification",
    });
    setShow(true);
  };

  return (
    <>
      <div className=" bg-black flex px-10 justify-between items-center w-screen h-24">
        {/* logo  */}
        <div className="">
          <Link to="/">
            <img
              src={require("../../assets/image/logo.png")}
              width="100px"
              className=""
              alt=""
            />
          </Link>
        </div>

        {/* menu bar */}

        <div className="hidden lg:block">
          <ul className=" flex justify-between  items-center">
            <li className="list">
              <Link className="text-white  uppercase  font-bold text-sm" to="/">
                Home
              </Link>
            </li>
            <li className="list">
              <Link
                className="text-white  uppercase font-bold text-sm"
                to="/history"
              >
                History
              </Link>
            </li>
            <li className="list">
              <Link className="text-white  uppercase font-bold text-sm" to="#">
                Member
              </Link>
            </li>
            <li className="list">
              <Link
                className="text-white  uppercase font-bold text-sm"
                to="/profile"
              >
                Profile
              </Link>
            </li>
          </ul>
        </div>

        <div className=" ">
          <ul className=" flex w-full gap-5  justify-between items-center">
            <li>
              {user ? (
                <Link
                  to={"/profile"}
                  className="  cursor-pointer flex items-center gap-3"
                >
                  <div>
                    <img
                      src={
                        user?.image
                          ? `${Base_url}/${user?.image}`
                          : require("../../assets/image/profile.jpg")
                      }
                      className=" w-12 h-12 rounded-full"
                      alt=""
                    />
                  </div>
                  <div className="  flex flex-col ">
                    <span className=" text-white sm:block hidden">
                      {user?.userName}
                    </span>
                    <span className=" text-sm text-gray-400 sm:block hidden">
                      {user?.email}
                    </span>
                  </div>
                </Link>
              ) : (
                <li className="list">
                  <Link
                    className="text-white  uppercase font-bold text-sm"
                    to="/login"
                  >
                    Login
                  </Link>
                </li>
              )}
            </li>
            <li className=" relative">
              <div className="  relative " onClick={openModal}>
                <IoNotificationsOutline color="white" size={30} />
                <span className=" bg-red-600 w-5 h-5 flex justify-center items-center rounded-full absolute -top-1 right-0">
                  <span className=" text-white text-sm">
                    {getNotifications?.length}
                  </span>
                </span>
              </div>

              {notificationsModal === true ? (
                <div className="  mx-auto md:w-96  w-72 mt-2 rounded-lg  z-50 overflow-hidden absolute shadow-md dark:bg-black  border  right-0 top-20   bg-[#1B1B1B]">
                  <div className=" px-3 py-3.5 flex justify-between bg-black  gap-2">
                    <h1 className=" text-white font-bold ">Notifications</h1>
                    <span className=" px-3 bg-red-500 rounded-xl text-white">
                      {users?.length} unread
                    </span>
                  </div>
                  {/* <hr className=" dark:border-[#1f1f1f]" /> */}
                  <div className=" h-56 overflow-y-auto">
                    {getNotifications?.length === 0 ? (
                      <div className=" flex h-56 justify-center items-center">
                        <div>
                          <IoNotificationsOffCircleOutline
                            size={80}
                            className="  mx-auto text-white"
                          />
                          <h1 className="  text-white">No Message Yet</h1>
                        </div>
                      </div>
                    ) : (
                      <>
                        {getNotifications?.map((item, index) => {
                          return (
                            <>
                              <div className=" p-4 bg-[#1B1B1B]">
                                <div className=" flex gap-3 ">
                                  <div className=" md:block hidden">
                                    <img
                                      src={require("../../assets/image/logo.png")}
                                      className=" w-10 h-10 object-cover rounded-full"
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <h1 className=" text-white  font-semibold ">
                                      {item?.title}
                                    </h1>
                                    <p className=" text-white text-sm">
                                      {item?.message}
                                    </p>
                                  </div>
                                </div>

                                <div className=" flex justify-between items-center">
                                  <span className=" text-gray-300 text-sm">
                                    {item?.createdAt}
                                  </span>
                                  <button
                                    onClick={() => {
                                      const params = {
                                        read: true,
                                      };
                                      axios
                                        .put(
                                          `${Base_url}/apis/userNotification/update/${item?._id}`,
                                          params
                                        )
                                        .then((res) => {
                                          console.log(res);

                                          if (
                                            res.data.data.title ===
                                            "Deposit Request"
                                          ) {
                                            navigate(`/history`);
                                          } else {
                                            navigate(`/history`);
                                          }
                                        })
                                        .catch((err) => {
                                          console.log(err);
                                        });
                                    }}
                                    className={
                                      " bg-[#685442]  text-sm py-1.5 px-3 rounded-md text-white"
                                    }
                                  >
                                    Read
                                  </button>
                                </div>
                              </div>
                              <hr className=" dark:border-[#1f1f1f]" />
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              ) : null}
            </li>
          </ul>
        </div>

        {/* button menu */}

        {/* <div></div> */}
        {/* 
        <div>
          <DestopLanguage />
        </div> */}
      </div>
    </>
  );
};

export default Navbar;
