import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import addNotification from "react-push-notification";
const firebaseConfig = {
  apiKey: "AIzaSyClCtyLbPVQrJDcArQHulwNs-2jBpV-R5k",
  authDomain: "harbor-capital-club-8dd5c.firebaseapp.com",
  projectId: "harbor-capital-club-8dd5c",
  storageBucket: "harbor-capital-club-8dd5c.appspot.com",
  messagingSenderId: "457764984860",
  appId: "1:457764984860:web:869d361d95dc6e717fe7d9",
  measurementId: "G-VDTB5HTGNT"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BKRiIZsvH5MfakZDAg1pMW-kNsW7kDnMRvXCAU39Wmd6Nc0tk9y_ozVvwZqwpyANaA_8-tgUXxcyBRJx_2mOzNw",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        localStorage.setItem("fcmToken", currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);

      addNotification({
        title:payload.notification.title,
        subtitle:'Harbor Capital Club',
        message:payload.notification.body,
        theme:'darkblue',
        icon:require('./assets/image/logo.png'),
        badge:require('./assets/image/logo.png'),
        native: true,
        onClick: () => {
         
          window.open('https://harborcapitalclub.com/');
      } 
    });
    });
  });
