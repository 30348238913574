import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import axios from "axios";
import { Base_url } from "../../../utils/Base_url";
import Swal from "sweetalert2";

const AllHistory = () => {
  const [users, setUsers] = useState([]);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  console.log(isUpdateOpen);

  const [deleteUser, setDeleteUser] = useState({});

  console.log(deleteUser, "deleteUser");

  useEffect(() => {
    axios
      .get(`${Base_url}/customer/getAll`)
      .then((res) => {
        console.log(res);

        setUsers(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(users);

  const removeFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A47ABF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${Base_url}/customer/delete/${id}`)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");

              axios
                .get(`${Base_url}/customer/getAll`)
                .then((res) => {
                  console.log(res.data);

                  setUsers(res.data.data);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <Wrapper>
      <div className=" flex   justify-between items-center">
        <div>
          <h2 className="text-white text-2xl">User Management</h2>
          
        </div>
      </div>

      <section className="mb-20 mt-7 text-gray-800">
        <div className="block rounded-lg shadow-lg">
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full mb-0">
                    <thead className=" bg-[#685442]">
                      <tr className=" rounded-lg whitespace-nowrap ">
                        <th
                          scope="col"
                          className=" text-sm text-white  font-bold px-6 py-4"
                        >
                          No
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-white  font-bold px-6 py-4"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className=" text-sm text-white  font-bold px-6 py-4"
                        >
                          Email
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          Phone Number
                        </th>

                        <th
                          scope="col"
                          className="text-sm  text-white   font-bold px-6 py-4"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {/* {users?.map((item, index) => {
                        
                          return (
                            <> */}
                      <tr className="bg-black border-t   rounded-md ">
                        <th
                          scope="row"
                          className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                        >
                          <p className="mb-0.5 font-medium text-white">#1</p>
                        </th>
                        <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                          <span className=" text-base text-white  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline    rounded-full">
                            ghulam mustafa
                          </span>
                        </td>
                        <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                          <span className=" text-base text-white  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline    rounded-full">
                            gm12@gmail.com
                          </span>
                        </td>
                        <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                          <span className=" text-base text-white  py-1 px-2.5 leading-none  whitespace-nowrap     rounded-full">
                            03090962660
                          </span>
                        </td>

                        <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                          <div className=" flex justify-center gap-2">
                            <button className={`  bg-[#685442] py-2 px-5 text-white`} >Delete</button>
                          </div>
                        </td>
                      </tr>
                      <tr className="bg-black border-t   rounded-md ">
                        <th
                          scope="row"
                          className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                        >
                          <p className="mb-0.5 font-medium text-white">#1</p>
                        </th>
                        <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                          <span className=" text-base text-white  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline    rounded-full">
                            ghulam mustafa
                          </span>
                        </td>
                        <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                          <span className=" text-base text-white  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline    rounded-full">
                            gm12@gmail.com
                          </span>
                        </td>
                        <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                          <span className=" text-base text-white  py-1 px-2.5 leading-none  whitespace-nowrap     rounded-full">
                            03090962660
                          </span>
                        </td>

                        <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                          <div className=" flex justify-center gap-2">
                            <button className={`  bg-[#685442] py-2 px-5 text-white`} >Delete</button>
                          </div>
                        </td>
                      </tr>
                      <tr className="bg-black border-t   rounded-md ">
                        <th
                          scope="row"
                          className="text-sm font-normal px-6 py-4   whitespace-nowrap "
                        >
                          <p className="mb-0.5 font-medium text-white">#1</p>
                        </th>
                        <td className="align-middle text-sm font-normal px-6 py-4 whitespace-nowrap  text-center">
                          <span className=" text-base text-white  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline    rounded-full">
                            ghulam mustafa
                          </span>
                        </td>
                        <td className="text-sm font-normal text-center px-6 py-4 whitespace-nowrap">
                          <span className=" text-base text-white  py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline    rounded-full">
                            gm12@gmail.com
                          </span>
                        </td>
                        <td className="align-middle text-center text-sm font-normal px-6 py-4 whitespace-nowrap text-left">
                          <span className=" text-base text-white  py-1 px-2.5 leading-none  whitespace-nowrap     rounded-full">
                            03090962660
                          </span>
                        </td>

                        <td className="align-middle  text-sm font-normal px-6 py-4 whitespace-nowrap">
                          <div className=" flex justify-center gap-2">
                            <button className={`  bg-[#685442] py-2 px-5 text-white`} >Delete</button>
                          </div>
                        </td>
                      </tr>
                      {/* </> */}
                      {/* );
                       
                      })} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default AllHistory;
