import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
const AdminLogin = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
    
  const submit = (e)=>{

    e.preventdefault()
    if(state.email.length===0){

      toast.error('Please enter your email');

    }else if(state.password.length===0){
      toast.error('Please enter your password');
    }else{
      navigate('/admin/dashboard')
    }
  }

  return (
    <div className="bg-black1 h-screen bg-Hero bg-center bg-cover flex justify-center items-center">
      <form  className=" bg-transparent border-2 border-white border-opacity-20 backdrop-blur-md  p-6 shadow-2xl  w-10/12 sm:w-8/12 md:w-6/12 lg:w-4/12  wrapper   mx-autoborder-opacity-20  text-white rounded-3xl">
        <div className=" text-center">
          <img
            src={require("../../assets/image/logo.png")}
            className=" mx-auto w-32"
            alt=""
          />
          <h3 className="mb-4 text-white capitalize font-semibold text-lg">
            dashboard login
          </h3>
        </div>

        <div className="relative mb-7 border rounded-full">
            <input
             
               
              name={"email"}
              type="text"
              placeholder="Please enter 6~12 letters or numbers"
              className="w-full h-full bg-transparent border-none outline-none border-2 border-white border-opacity-20 rounded-full text-md text-white px-5 py-3.5 placeholder-white"
            />
            <i className="bx bxs-user absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"></i>
          </div>
          <div className="relative mb-7 border rounded-full">
            <input
              
                
               name={"password"}
             
              type="password"
              placeholder="Please enter the Password"
              className="w-full h-full bg-transparent border-none outline-none border-2 border-white border-opacity-20 rounded-full text-md text-white px-5 py-3.5 placeholder-white"
            />
            <i className="bx bxs-lock-alt absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"></i>
          </div>
        <Link to={'/admin/dashboard'} className="mb-4">
            <button
           
            className="w-full h-11 bg-white border-none outline-none rounded-full shadow-sm cursor-pointer text-lg text-gray-800 font-semibold hover:bg-gray-100"
          >
            Login Now
          </button>
        </Link>
      </form>
    </div>
  );
};
export default AdminLogin;
