import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Base_url } from "../../utils/Base_url";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import toast from "react-hot-toast";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
const Register = () => {
  const { id } = useParams();

  const [state, setState] = useState({
    invitationCode: id,
    userName: "",
    email: "",
    loginPassword: "",
    withdrawPassword: "",
    referralCode: "",
    realName: "",
    phone: "",
    confirm_passwrod: "",
    block: "",
  });
  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  console.log(state);

  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState(""); // State for storing the country name

  console.log(countryName);
  // const apiKey = "AIzaSyA0ezzOFq6lTHs4i4DlmMPJpV48LAWMx7o";

  useEffect(() => {
    const fetchCountryCodeAndName = async (latitude, longitude) => {
      const apiKey = "YOUR_API_KEY_HERE"; // Replace with your actual API key
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyA0ezzOFq6lTHs4i4DlmMPJpV48LAWMx7o`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        const countryInfo = data.results
          .flatMap((result) => result.address_components)
          .find((component) => component.types.includes("country"));

        if (countryInfo) {
          setCountry(countryInfo.short_name.toLowerCase());
          setCountryName(countryInfo.long_name);
        }
      } catch (error) {
        // console.error("Error fetching country info: ", error);
      }
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetchCountryCodeAndName(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          // console.error("Error getting location: ", error);
        }
      );
    } else {
      // console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const fcm = localStorage.getItem("fcmToken");

  const navigate = useNavigate();
  const [loading, setLoader] = useState(false);
  const handleSubmit = (e) => {
    console.log(e);

    e.preventDefault();

    if (state.userName.length === 0) {
      toast.error("Please enter username");
    } else if (state.referralCode.length === 0 && !id) {
      toast.error("must enter Referral Code");
    } else if (state.loginPassword.length === 0) {
      toast.error("Please enter password");
    } else if (state.confirm_passwrod.length === 0) {
      toast.error("Please enter confirm password");
    } else if (state.loginPassword !== state.confirm_passwrod) {
      toast.error("The two password  don't match");
    } else if (!phone) {
      toast.error("Please enter phone number");
    } else if (state.withdrawPassword.length === 0) {
      toast.error("Please enter withdraw password");
    } else {
      setLoader(true);
      const params = new FormData();
      params.append("fcmtoken", fcm);
      params.append("invitationCode", id ? id : "");
      params.append("referralCode", id ? Date.now() : state.referralCode);
      params.append("userName", state.userName);
      params.append("email", state.email);
      params.append("loginPassword", state.loginPassword);
      params.append("withdrawPassword", state.withdrawPassword);
      params.append("realName", state.realName);
      params.append("phone", phone);

      axios
        .post(`${Base_url}/apis/user/create`, params)
        .then((res) => {
          // console.log(res);
          if (res?.data?.status === "ok") {
            toast.success("User register successfully!");
            setLoader(false);
            navigate("/login");
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
          //  toast.error('Invitation code does not match!')
          if (error?.response?.data?.status === "fail") {
            setLoader(false);
            toast.error(error?.response?.data?.message);
          } else {
          }
        });
    }
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const [referCodeGenerate, setReferCodeGenerate] = useState(null);
  function generateRandomCode() {
    const length = 6;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]|;:,.<>?";
    let randomCode = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomCode += charset[randomIndex];
    }
    return setReferCodeGenerate(randomCode);
  }

  return (
    <div className=" flex items-center  text-center  overflow-x-hidden  bg-Hero bg-center bg-cover w-full   h-auto py-12 justify-center">
      <div className="wrapper  w-[480px] mx-auto bg-transparent border-2 border-white border-opacity-20 backdrop-blur-md text-white rounded-3xl p-7">
        <form onSubmit={handleSubmit}>
          <h1 className="text-3xl text-center mb-8">Register</h1>

          {!id ? (
            <div className="relative mb-7 border rounded-full">
              <input
                onChange={handleInputs}
                name={"referralCode"}
                value={state.referralCode}
                type="number"
                placeholder="Please enter digits invitation code"
                className="w-full h-full bg-transparent border-none outline-none border-2 border-white border-opacity-20 rounded-full text-mc text-white px-5 py-3 placeholder-white"
              />
              <i
                // onClick={() => generateRandomCode()}
                className="bx bxs-lock absolute right-0 top-1/2 transform -translate-y-1/2  text-lg px-5 py-1 rounded-full"
              ></i>
            </div>
          ) : (
            <div className="relative mb-7 border rounded-full">
              <input
                disabled
                onChange={handleInputs}
                // value={state.invitationCode}
                name={"invitationCode"}
                type="text"
                placeholder="Please enter invitation code"
                defaultValue={id}
                className="w-full h-full bg-transparent border-none outline-none border-2 border-white border-opacity-20 rounded-full text-mc text-white px-5 py-3 placeholder-white"
              />
              <i className="bx bxs-user absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"></i>
            </div>
          )}

          <div className="relative mb-7 border rounded-full">
            <input
              onChange={handleInputs}
              value={state.userName}
              name={"userName"}
              type="text"
              placeholder="Username"
              className="w-full h-full bg-transparent border-none outline-none border-2 border-white border-opacity-20 rounded-full text-lg text-white px-5 py-2.5 placeholder-white"
            />
            <i className="bx bxs-user absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"></i>
          </div>
          <div className="relative mb-7 border rounded-full">
            <input
              onChange={handleInputs}
              value={state.email}
              name={"email"}
              type="text"
              placeholder="Email"
              className="w-full h-full bg-transparent border-none outline-none border-2 border-white border-opacity-20 rounded-full text-lg text-white px-5 py-2.5 placeholder-white"
            />
            <i className="bx bxs-message absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"></i>
          </div>

          <div className="relative mb-7 border rounded-full">
            <input
              onChange={handleInputs}
              value={state.loginPassword}
              name={"loginPassword"}
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Login Password"
              className="w-full h-full bg-transparent border-none outline-none border-2 border-white border-opacity-20 rounded-full text-lg text-white px-5 py-2.5 placeholder-white"
            />

            {isPasswordVisible ? (
              <FaRegEye
                onClick={togglePasswordVisibility}
                className=" text-white   absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"
              />
            ) : (
              <FaRegEyeSlash
                onClick={togglePasswordVisibility}
                className=" text-white   absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"
              />
            )}
          </div>
          <div className="relative mb-7 border rounded-full">
            <input
              onChange={handleInputs}
              value={state.confirm_passwrod}
              name={"confirm_passwrod"}
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Confirm Password"
              className="w-full h-full bg-transparent border-none outline-none border-2 border-white border-opacity-20 rounded-full text-lg text-white px-5 py-2.5 placeholder-white"
            />

            {isPasswordVisible ? (
              <FaRegEye
                onClick={togglePasswordVisibility}
                className=" text-white   absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"
              />
            ) : (
              <FaRegEyeSlash
                onClick={togglePasswordVisibility}
                className=" text-white   absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"
              />
            )}
          </div>
          <div className="relative mb-7 border rounded-full">
            <input
              onChange={handleInputs}
              value={state.realName}
              name={"realName"}
              type="text"
              placeholder="Real name"
              className="w-full h-full bg-transparent border-none outline-none border-2 border-white border-opacity-20 rounded-full text-lg text-white px-5 py-2.5 placeholder-white"
            />
            <i className="bx bxs-user absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"></i>
          </div>

          <div className="relative flex mb-7 border rounded-full">
            <PhoneInput
              country={country}
              enableSearch={true}
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
            {/* <input
              onChange={handleInputs}
              value={state.phone}
              name={"phone"}
              type="text"
              placeholder="Please enter phone number"
              className="w-full h-full ml-6 bg-transparent border-none outline-none border-2 border-white border-opacity-20 rounded-full text-lg text-white px-5 py-2.5 placeholder-white"
            /> */}
            <i className="bx bxs-phone-call absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"></i>
          </div>

          <div className="relative mb-7 border rounded-full">
            <input
              onChange={handleInputs}
              value={state.withdrawPassword}
              name={"withdrawPassword"}
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Withdrawal  Password"
              className="w-full h-full bg-transparent border-none outline-none border-2 border-white border-opacity-20 rounded-full text-lg text-white px-5 py-2.5 placeholder-white"
            />

            {isPasswordVisible ? (
              <FaRegEye
                onClick={togglePasswordVisibility}
                className=" text-white   absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"
              />
            ) : (
              <FaRegEyeSlash
                onClick={togglePasswordVisibility}
                className=" text-white   absolute right-5 top-1/2 transform -translate-y-1/2 text-lg"
              />
            )}
          </div>

          {loading === false ? (
            <button
              type="submit"
              className="w-full h-11 bg-white border-none outline-none rounded-full shadow-sm cursor-pointer text-lg text-gray-800 font-semibold hover:bg-gray-100"
            >
              Register Now
            </button>
          ) : (
            <button
              disabled
              type="button"
              class="w-full h-11 bg-white border-none outline-none rounded-full shadow-sm cursor-pointer text-lg text-gray-800 font-semibold hover:bg-gray-100"
            >
              <svg
                aria-hidden="true"
                role="status"
                class="inline w-4 h-4 me-3 text-black animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              Loading...
            </button>
          )}

          <div className="text-sm text-center my-5">
            <p>
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-white font-semibold no-underline hover:underline"
              >
                Login here
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
